import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Divider,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublicIcon from "@mui/icons-material/Public";
import DashboardIcon from "@mui/icons-material/Dashboard";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import NavBar from "../NavBar";
import { useTheme } from "@mui/material/styles";

// Extend dayjs with timezone support
dayjs.extend(utc);
dayjs.extend(timezone);

export default function AnalyticsDashboard() {
  const theme = useTheme();
  const [analyticsData, setAnalyticsData] = useState(null);
  const { token } = useSelector((state) => state.authSlice);
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    const handleGetAnalytics = async () => {
      try {
        const result = await axios.get("/api/analytics/getAnalytics", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setAnalyticsData(result.data);
      } catch (error) {
        console.error("Failed to fetch analytics:", error);
      } finally {
        setLoading(false);
      }
    };

    handleGetAnalytics();
  }, [token]);

  if (loading) {
    return (
      <Box p={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const localTz = dayjs.tz.guess();

  const {
    publicWorkoutViews = [],
    feedbackSubmissions = [],
    userLoginData = [],
    landingPageData = [],
    userSignupData = [],
    exerciseEvents = [],
  } = analyticsData || {};

  const stats = [
    {
      key: "publicWorkoutViews",
      title: "Public Workout Views",
      icon: <PublicIcon color="primary" />,
      count: publicWorkoutViews.length,
      latest: publicWorkoutViews[0]?.timestamp,
      data: publicWorkoutViews,
      columns: ["timestamp", "blob1", "index1"],
    },
    {
      key: "userSignupData",
      title: "User Signups",
      icon: <PersonAddIcon color="success" />,
      count: userSignupData.length,
      latest: userSignupData[0]?.timestamp,
      data: userSignupData,
      columns: ["timestamp", "blob1", "index1"],
    },
    {
      key: "userLoginData",
      title: "Login Events",
      icon: <DashboardIcon color="info" />,
      count: userLoginData.length,
      latest: userLoginData[0]?.timestamp,
      data: userLoginData,
      columns: ["timestamp", "blob1", "index1"],
    },
    {
      key: "landingPageData",
      title: "Landing Page Hits",
      icon: <VisibilityIcon color="secondary" />,
      count: landingPageData.length,
      latest: landingPageData[0]?.timestamp,
      data: landingPageData,
      columns: ["timestamp", "blob1", "blob2", "blob3"],
    },
    {
      key: "feedbackSubmissions",
      title: "Feedback Submissions",
      icon: <FeedbackIcon color="warning" />,
      count: feedbackSubmissions.length,
      latest: feedbackSubmissions[0]?.timestamp,
      data: feedbackSubmissions,
      columns: [
        "timestamp",
        "email",
        "feedback",
        "feedback_type",
        "submitted_at",
      ],
    },
    {
      key: "exerciseEvents",
      title: "Exercise Events",
      icon: <ShowChartIcon color="error" />,
      count: exerciseEvents.length,
      latest: exerciseEvents[0]?.timestamp,
      data: exerciseEvents,
      columns: ["timestamp", "blob1", "index1"],
    },
  ];

  return (
    <Box>
      <NavBar />
      <Box p={4}>
        <Typography
          sx={{ color: theme.palette.text.primary }}
          variant="h4"
          gutterBottom
        >
          User Activity Dashboard
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <Grid container spacing={3}>
          {stats.map((stat) => (
            <Grid item xs={12} sm={6} md={4} key={stat.title}>
              <Card
                elevation={4}
                onClick={() => setActiveCategory(stat.key)}
                sx={{ cursor: "pointer" }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    {stat.icon}
                    <Typography variant="h6" ml={1}>
                      {stat.title}
                    </Typography>
                  </Box>
                  <Typography variant="h4" fontWeight="bold">
                    {stat.count}
                  </Typography>
                  <Chip
                    label={`Latest: ${
                      stat.latest
                        ? dayjs(stat.latest)
                            .tz(localTz)
                            .format("MMM D, YYYY hh:mm A")
                        : "N/A"
                    }`}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {activeCategory && (
          <Box mt={6}>
            <Typography variant="h5" gutterBottom>
              {stats.find((s) => s.key === activeCategory)?.title} Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Table size="small">
              <TableHead>
                <TableRow>
                  {stats
                    .find((s) => s.key === activeCategory)
                    ?.columns.map((col) => (
                      <TableCell key={col}>{col}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stats
                  .find((s) => s.key === activeCategory)
                  ?.data.map((row, idx) => (
                    <TableRow key={idx}>
                      {stats
                        .find((s) => s.key === activeCategory)
                        ?.columns.map((col) => (
                          <TableCell key={col}>{row[col]}</TableCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </Box>
  );
}
