import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWorkoutTemplate,
  getWorkout,
  clearCurrentTemplate,
  setCurrentWorkout,
} from "../../redux/slices/workoutSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getProfile } from "../../redux/slices/profileSlice";
import WorkoutTemplateMenu from "./WorkoutTemplateMenu";
import Exercise from "../Workout/Exercise";
import axios from "axios";
import WorkoutTitle from "../Workout/WorkoutTitle";
import NavBar from "../NavBar";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useParams, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { getRootDomain } from "../../utils/get_domain";
import WorkoutStats from "../Workout/WorkoutStats";
import InsightsIcon from "@mui/icons-material/Insights";
import WorkoutShareDialog from "../shared/WorkoutShareDialog";

const WorkoutTemplate = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const { workoutTemplates } = useSelector((state) => state.profileSlice);
  const { workout_id } = useParams();
  const { workout } = useSelector((state) => state.workoutSlice);
  const [workoutActivities, setWorkoutActivities] = useState([]);
  const [selectedTemplateHasChildren, setSelectedTemplateHasChildren] =
    useState(null);
  const [bottomNavigationState, setBottomNavigationState] = useState(1);
  const [selectedIdForEdit, setSelectedIdForEdit] = useState(null);
  const [activityForEdit, setActivityForEdit] = useState(null);
  const [workoutStats, setWorkoutStats] = useState(null);
  const [shareLink, setShareLink] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(null);

  useEffect(() => {
    if (workout_id) {
      dispatch(getWorkout({ token, workout_id }));

      const has_children =
        workoutTemplates.filter(
          (template) =>
            template.workout_id === workout_id && template.has_children
        ).length > 0;

      setSelectedTemplateHasChildren(has_children);
      setBottomNavigationState(has_children ? 1 : 2);
    }
  }, [workout_id, dispatch, token, workoutTemplates]);

  useEffect(() => {
    if (workout) {
      setWorkoutActivities(workout.allWorkoutActivities);
      setWorkoutStats(workout.stats);
    }
  }, [workout]);

  const handleDeleteWorkout = async () => {
    try {
      await dispatch(deleteWorkoutTemplate({ token, workout_id }));
      navigate("/");
    } catch (error) {
      console.error("Failed to delete workout:", error);
    }
  };

  const handleFinishTemplate = () => {
    try {
      dispatch(clearCurrentTemplate());
      dispatch(getProfile({ token }));
      navigate("/");
    } catch (error) {
      console.error("Failed to finish workout:", error);
    }
  };

  const handleAddExerciseToWorkout = async (exercise) => {
    const result = await axios.post(
      `/api/exercise/createExercise`,
      {
        workout_id,
        exercise,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { exercise_id } = result.data;
    const savedExercise = {
      type: "exercise",
      parsed: `${exercise.reps} X ${exercise.sets} ${exercise.name} @ ${
        exercise.weight
      } ${exercise.exerciseUnit === 0 ? "lbs" : "kgs"}`,
      data: {
        exercise_id,
        name: exercise.name,
        sets: exercise.sets,
        reps: exercise.reps,
        weight: exercise.weight,
        weight_unit: exercise.exerciseUnit,
      },
    };
    setWorkoutActivities([...workoutActivities, savedExercise]);
    if (bottomNavigationState === 3) {
      setBottomNavigationState(1);
    }
  };

  const handleAddRowToWorkout = async (row) => {
    await axios.post(
      `/api/exercise/createRow`,
      {
        workout_id,
        row,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const savedRow = { parsed: `${row.rowDistance}m row in ${row.rowTime}` };
    setWorkoutActivities([...workoutActivities, savedRow]);
    if (bottomNavigationState === 3) {
      setBottomNavigationState(1);
    }
  };

  const handleAddRunToWorkout = async (run) => {
    await axios.post(
      `/api/exercise/createRun`,
      {
        workout_id,
        run,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const savedRun = {
      parsed: `${run.runDistance} meter run in ${run.runTime}`,
    };
    setWorkoutActivities([...workoutActivities, savedRun]);
    if (bottomNavigationState === 3) {
      setBottomNavigationState(1);
    }
  };

  const handleCreateWorkoutFromTemplate = async (config = "default") => {
    const response = await axios.post(
      `/api/workout/createWorkoutFromTemplate`,
      { template_id: workout_id, config },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    handleShowWorkout(response.data.workout_id);
  };

  const handleShowWorkout = (workout_id) => {
    dispatch(clearCurrentTemplate());
    dispatch(setCurrentWorkout(workout_id));
    navigate(`/workout/${workout_id}`);
  };

  const handleBottomNavigationChange = (value) => {
    setBottomNavigationState(value);
    if (selectedIdForEdit) {
      setSelectedIdForEdit(null);
      setActivityForEdit(null);
    }
  };

  const getActivityId = (activity) => {
    switch (activity.type) {
      case "exercise":
        return activity.data.exercise_id;
      case "run":
        return activity.data.run_id;
      case "row":
        return activity.data.row_id;
      default:
        console.log("not handled get activity id");
    }
  };

  const handleEditSelectChange = (activity) => {
    const id = getActivityId(activity);
    if (id === selectedIdForEdit) {
      setSelectedIdForEdit(null);
      setActivityForEdit(null);
    } else {
      setSelectedIdForEdit(id);
      setActivityForEdit(activity);
    }
  };

  const handleEditActivity = async (activity) => {
    await axios.post(
      `/api/exercise/editActivity`,
      {
        workout_id,
        activity,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setActivityForEdit(null);
    setSelectedIdForEdit(null);
    if (activity.type === "exercise") {
      const { data: exercise } = activity;
      const savedExercise = {
        type: "exercise",
        parsed: `${exercise.reps} X ${exercise.sets} ${exercise.name} @ ${
          exercise.weight
        } ${exercise.exerciseUnit === 0 ? "lbs" : "kgs"}`,
        data: {
          ...exercise,
        },
      };
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === exercise.exercise_id) {
          return savedExercise;
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }

    if (activity.type === "run") {
      const { data: run } = activity;
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === run.run_id) {
          return {
            parsed: `${run.distance} ${a.data.distance_type} run in ${run.time}`,
            type: "run",
            data: {
              ...a.data,
              ...run,
            },
          };
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }

    if (activity.type === "row") {
      const { data: row } = activity;
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === activity.data.row_id) {
          console.log("out", row, a);
          return {
            parsed: `${row.distance}m row in ${row.time}`,
            type: "row",
            data: {
              ...a.data,
              ...row,
            },
          };
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmModalOpen(false);
    setShareLink(null);
  };

  const handleShareWorkout = async (e) => {
    e.preventDefault();
    const result = await axios.post(
      `/api/workout/createWorkoutPublic`,
      {
        workout_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const root = getRootDomain();
    const link = `${root}/shared-workout/${result.data.workout_id}`;
    setShareLink(link);
    setConfirmModalOpen(true);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%" }}>
          <NavBar />
        </Box>
        <Box
          component={motion.div}
          layout
          transition={{ type: "spring", stiffness: 60, damping: 20 }}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              background: theme.palette.background.paper,
              borderRadius: 4,
              p: 4,
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
              width: 300,
              height: "auto",
              mx: "auto",
              my: 4,
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "5px",
                background: `linear-gradient(90deg, #FF5722 0%, ${theme.palette.tertiary.main} 100%)`,
              },
            }}
            component={motion.div}
            layout
          >
            {workout ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: "16px",
                }}
              >
                <Box>
                  <WorkoutTitle />
                </Box>
                <Box>
                  <WorkoutTemplateMenu
                    handleDeleteWorkoutTemplate={handleDeleteWorkout}
                    handleFinishWorkoutTemplate={handleFinishTemplate}
                    handleShareWorkout={handleShareWorkout}
                  />
                </Box>
              </Box>
            ) : null}

            <AnimatePresence>
              {bottomNavigationState === 0 || bottomNavigationState === 1 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    width: "100%",
                    maxHeight:
                      bottomNavigationState === 0 && selectedIdForEdit
                        ? "64px"
                        : "440px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px", // Width of the scrollbar
                      height: "64px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f0f0f0", // Scrollbar track color
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#888", // Scrollbar handle color
                      borderRadius: "10px",
                      "&:hover": {
                        background: "#555", // Darker color on hover
                      },
                    },
                  }}
                >
                  {bottomNavigationState !== 3 && bottomNavigationState !== 4
                    ? workoutActivities.map((activity, index) => {
                        if (
                          selectedIdForEdit &&
                          getActivityId(activity) !== selectedIdForEdit
                        )
                          return null;
                        return (
                          <Box
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              paddingBottom: "4px",
                              paddingTop: "4px",
                            }}
                          >
                            <Typography key={index} variant="body1">
                              {activity.parsed}
                            </Typography>
                            {bottomNavigationState === 0 ? (
                              <Radio
                                checked={
                                  selectedIdForEdit === getActivityId(activity)
                                }
                                onClick={() => handleEditSelectChange(activity)}
                                name={activity.parsed}
                                inputProps={{ "aria-label": activity.parsed }}
                              />
                            ) : null}
                          </Box>
                        );
                      })
                    : null}
                </Box>
              ) : null}
              {bottomNavigationState === 4 ? (
                <WorkoutStats stats={workoutStats} />
              ) : null}
              {bottomNavigationState === 3 ? (
                <Exercise
                  addRow={handleAddRowToWorkout}
                  addExercise={handleAddExerciseToWorkout}
                  addRun={handleAddRunToWorkout}
                  workoutActivities={workoutActivities}
                  isEdit={false}
                />
              ) : null}
              {bottomNavigationState === 0 && selectedIdForEdit ? (
                <Exercise
                  handleEditActivity={handleEditActivity}
                  workoutActivities={workoutActivities}
                  activityForEdit={activityForEdit}
                  isEdit={true}
                />
              ) : null}
            </AnimatePresence>
            <Box>
              <List>
                {bottomNavigationState === 2 ? (
                  <>
                    {workoutActivities.length > 0 ? (
                      <Box sx={{ textAlign: "center" }}>
                        <ListItemButton
                          onClick={() =>
                            handleCreateWorkoutFromTemplate("default")
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Default weights"} />
                        </ListItemButton>
                      </Box>
                    ) : (
                      <Box>
                        <Typography>Add an Exercise to begin.</Typography>
                      </Box>
                    )}
                    {selectedTemplateHasChildren ? (
                      <Box>
                        <ListItemButton
                          onClick={() =>
                            handleCreateWorkoutFromTemplate("most_recent")
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Use last workout weight"} />
                        </ListItemButton>

                        <ListItemButton
                          onClick={() =>
                            handleCreateWorkoutFromTemplate("five_percent")
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Increase last workout weight by 5%"}
                          />
                        </ListItemButton>

                        <ListItemButton
                          onClick={() =>
                            handleCreateWorkoutFromTemplate("ten_percent")
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Increase last workout weight by 10%"}
                          />
                        </ListItemButton>
                      </Box>
                    ) : null}
                  </>
                ) : null}
              </List>
            </Box>
          </Card>
          <BottomNavigation
            showLabels
            value={"walue"}
            onChange={(event, newValue) => {
              handleBottomNavigationChange(newValue);
            }}
            sx={{ borderRadius: "16px", marginTop: "16px" }}
          >
            <BottomNavigationAction
              id="Edit"
              label="Edit"
              icon={<EditIcon />}
            />
            <BottomNavigationAction
              id="View"
              label="View"
              icon={<VisibilityIcon />}
            />
            <BottomNavigationAction
              id="Start"
              label="Start"
              icon={<PlayCircleFilledWhiteIcon />}
            />
            <BottomNavigationAction id="Add" label="Add" icon={<AddIcon />} />
            <BottomNavigationAction
              id="Stats"
              label="Stats"
              icon={<InsightsIcon />}
            />
          </BottomNavigation>
        </Box>
        <WorkoutShareDialog
          open={confirmModalOpen}
          handleClose={handleCloseConfirmDialog}
          link={shareLink}
        />
      </Box>
    </Box>
  );
};

export default WorkoutTemplate;
