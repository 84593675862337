import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  IconButton,
  Paper,
  Divider,
  InputAdornment,
  Link,
  CircularProgress,
  Alert,
} from "@mui/material";
import { validateEmail } from "../utils/account_verify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { login } from "../redux/slices/authSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkmark from "./shared/AnimatedCheckmark";
import ValidationCodeInput from "./ValidationCodeInput";
import { useNavigate } from "react-router-dom";

export default function LoginRegisterModal({ open, handleOpen, handleClose }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [modalViewState, setModalViewState] = useState("guide");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showValidateSuccess, setShowValidateSuccess] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    if (modalViewState === "validation") {
      setTimeout(() => {
        setShowResendButton(true);
      }, 10000);
    }
  }, [modalViewState]);

  useEffect(() => {
    if (showValidateSuccess) {
      setTimeout(() => {
        setModalViewState("login");
      }, 2000);
    }
  }, [showValidateSuccess]);

  useEffect(() => {
    if (resendDisabled) {
      setTimeout(() => {
        setResendDisabled(false);
      }, 30000);
    }
  }, [resendDisabled]);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }
    try {
      dispatch(login({ email, password }));
      setLoading(false);
      handleSetModalView("close");
    } catch (error) {
      setError("Invalid email or password.");
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    try {
      await axios.post(`/api/account/register`, { email, password });
      setLoading(false);
      alert("Registration successful. Please check email for validation code.");
      setModalViewState("validation");
    } catch (err) {
      setError(
        err.response?.data?.error || "Something went wrong. Please try again."
      );
      setLoading(false);
    }
  };

  const handleSetModalView = (viewType) => {
    if (viewType === "close") {
      setModalViewState("guide");
      handleClose();
    } else {
      setModalViewState(viewType);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitValidationCode = async (code) => {
    try {
      const result = await axios.post(`/api/account/validate`, { email, code });
      if (result.status === 200) {
        setShowValidateSuccess(true);
        setError(null);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError("Invalid or expired verification code.");
    }
  };

  const handleResend = async () => {
    try {
      await axios.post(`/api/account/register`, { email, password });
      setResendDisabled(true);
    } catch (err) {
      setError("Failed to resend code. Please try again later.");
    }
  };

  const handleModalStateChange = (modalState) => {
    switch (modalState) {
      case "login":
        return (
          <Box
            sx={{
              padding: "32px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              minHeight: "400px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <IconButton
                aria-label="back"
                onClick={() => handleSetModalView("guide")}
                sx={{
                  backgroundColor: theme.palette.background.default,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  marginBottom: "16px",
                }}
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: 700,
                  marginBottom: "8px",
                  color: theme.palette.text.primary,
                }}
              >
                {"Welcome Back"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.secondary }}
              >
                {"Please enter your credentials to continue"}
              </Typography>
            </Box>

            {error && (
              <Alert severity="error" sx={{ width: "100%" }}>
                {error}
              </Alert>
            )}

            <form onSubmit={handleLogin} style={{ width: "100%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                  placeholder="your.email@example.com"
                  InputProps={{
                    sx: { borderRadius: "8px" },
                  }}
                />

                <TextField
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete={"current-password"}
                  InputProps={{
                    sx: { borderRadius: "8px" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box sx={{ textAlign: "right" }}>
                  <Link
                    component="button"
                    type="button"
                    variant="body2"
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                    sx={{
                      textDecoration: "none",
                      color: theme.palette.primary.main,
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Forgot password?
                  </Link>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  disabled={loading}
                  sx={{
                    borderRadius: "8px",
                    padding: "12px",
                    textTransform: "none",
                    fontWeight: 600,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Sign In"
                  )}
                </Button>

                <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                  <Divider sx={{ flexGrow: 1 }} />
                  <Typography
                    variant="body2"
                    sx={{ mx: 2, color: theme.palette.text.secondary }}
                  >
                    or
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Box>

                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {"Don't have an account?"}
                    <Link
                      component="button"
                      type="button"
                      variant="body2"
                      onClick={() => setModalViewState("register")}
                      sx={{
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {"Sign Up"}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Box>
        );
      case "register":
        return (
          <Box
            sx={{
              padding: "32px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              minHeight: "400px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <IconButton
                aria-label="back"
                onClick={() => handleSetModalView("guide")}
                sx={{
                  backgroundColor: theme.palette.background.default,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  marginBottom: "16px",
                }}
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: 700,
                  marginBottom: "8px",
                  color: theme.palette.text.primary,
                }}
              >
                {"Create an Account"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.secondary }}
              >
                {"Sign up to get started"}
              </Typography>
            </Box>

            {error && (
              <Alert severity="error" sx={{ width: "100%" }}>
                {error}
              </Alert>
            )}

            <form onSubmit={handleRegister} style={{ width: "100%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                  placeholder="your.email@example.com"
                  InputProps={{
                    sx: { borderRadius: "8px" },
                  }}
                />

                <TextField
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete={"new-password"}
                  InputProps={{
                    sx: { borderRadius: "8px" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  disabled={loading}
                  sx={{
                    borderRadius: "8px",
                    padding: "12px",
                    textTransform: "none",
                    fontWeight: 600,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Sign Up"
                  )}
                </Button>

                <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                  <Divider sx={{ flexGrow: 1 }} />
                  <Typography
                    variant="body2"
                    sx={{ mx: 2, color: theme.palette.text.secondary }}
                  >
                    or
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Box>

                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {"Already have an account?"}
                    <Link
                      component="button"
                      type="button"
                      variant="body2"
                      onClick={() => setModalViewState("login")}
                      sx={{
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {"Sign In"}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Box>
        );
      case "validation":
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              minHeight: "400px",
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ width: "90%", padding: "24px" }}>
              <IconButton
                aria-label="back"
                onClick={() => handleSetModalView("guide")}
                sx={{
                  backgroundColor: theme.palette.background.default,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  marginBottom: "16px",
                }}
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box sx={{ textAlign: "center", padding: "24px" }}>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: 700,
                  marginBottom: "8px",
                  color: theme.palette.text.primary,
                }}
              >
                Check Email for Validation Code
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.secondary }}
              >
                Enter code below
              </Typography>
            </Box>
            {showValidateSuccess ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkmark strokeWidth={4} color={theme.palette.success.main} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <ValidationCodeInput
                  handleSubmitValidationCode={handleSubmitValidationCode}
                />
                {showResendButton ? (
                  <Button
                    sx={{ marginTop: "8px" }}
                    disabled={resendDisabled}
                    onClick={handleResend}
                  >
                    Resend
                  </Button>
                ) : null}
              </Box>
            )}
          </Box>
        );
      case "guide":
        return (
          <Box
            sx={{
              padding: "32px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: 700,
                marginBottom: "8px",
                color: theme.palette.text.primary,
                textAlign: "center",
              }}
            >
              Weightliftr
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "20px",
                color: theme.palette.text.primary,
                textAlign: "center",
              }}
            >
              In order to use Weightliftr features register or login.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleSetModalView("login")}
                fullWidth
                sx={{
                  borderRadius: "8px",
                  padding: "10px",
                  textTransform: "none",
                  fontWeight: 600,
                }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSetModalView("register")}
                fullWidth
                sx={{
                  borderRadius: "8px",
                  padding: "10px",
                  textTransform: "none",
                  fontWeight: 600,
                }}
              >
                Register
              </Button>
            </Box>

            <Button
              variant="text"
              onClick={() => handleSetModalView("close")}
              sx={{
                marginTop: "8px",
                textTransform: "none",
              }}
            >
              Back
            </Button>
          </Box>
        );

      default:
        console.log("Login modal state not handled");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        paddingTop: "20%",
      }}
    >
      <Paper
        elevation={6}
        sx={{
          width: "100%",
          maxWidth: "400px",
          borderRadius: "12px",
          overflow: "hidden",
          background: theme.palette.background.paper,
        }}
      >
        {handleModalStateChange(modalViewState)}
      </Paper>
    </Modal>
  );
}
