import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import {
  FitnessCenter as FitnessCenterIcon,
  Timeline as TimelineIcon,
  BarChart as BarChartIcon,
  EmojiEvents as EmojiEventsIcon,
  Group as GroupIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

const FeaturesSection = () => {
  const features = [
    {
      title: "Hybrid Workout Tracking",
      description:
        "Log any combination of exercises - from weightlifting to calistenics to cardio - in one seamless workout session.",
      icon: <FitnessCenterIcon fontSize="large" />,
    },
    {
      title: "Progressive Overload",
      description:
        "Automatically calculate and suggest weight increases to ensure continuous strength gains.",
      icon: <TimelineIcon fontSize="large" />,
    },
    {
      title: "Detailed Analytics",
      description:
        "Visualize your progress with comprehensive charts and statistics for all your lifts and workouts.",
      icon: <BarChartIcon fontSize="large" />,
    },
    {
      title: "Weight Stays On The Rack",
      description:
        "Automatically populate the previous weight, reps, and sets when selecting an exercise.",
      icon: <EmojiEventsIcon fontSize="large" />,
    },
    {
      title: "Define Your Own Exercises",
      description:
        "Tired of being boxed in to a list of specific exercises? Define your own.",
      icon: <GroupIcon fontSize="large" />,
    },
    {
      title: "Customizable Workouts",
      description:
        "Create and save custom workout routines tailored to your specific fitness goals.",
      icon: <SettingsIcon fontSize="large" />,
    },
  ];

  return (
    <Box
      id="features"
      component="section"
      sx={{
        py: { xs: 12, md: 12, lg: 16 },
        bgcolor: "grey.50",
      }}
    >
      <Container>
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            sx={{
              borderRadius: 4,
              px: 2,
              py: 0.5,
              mb: 2,
              textTransform: "none",
              pointerEvents: "none",
            }}
          >
            Features
          </Button>
          <Typography variant="h2" component="h2" gutterBottom>
            Everything You Need to Crush Your Goals
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              maxWidth: 900,
              mx: "auto",
              fontSize: { md: "1.25rem" },
            }}
          >
            Weightliftr provides all the tools you need to track, improve, and
            dominate your fitness training journey.
          </Typography>
        </Box>

        <Grid container spacing={4} justifyContent="center">
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  border: 1,
                  borderColor: "grey.200",
                  borderRadius: 3,
                }}
              >
                <Box sx={{ color: "primary.main", mb: 2 }}>{feature.icon}</Box>
                <Typography variant="h5" component="h3" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesSection;
