import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HoneycombGrid from "./HoneycombGrid"; // Import the HoneycombGrid
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { motion } from "framer-motion";

function HeroSection() {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const fullHoneycomb = [5, 6, 7, 8, 9, 8, 7, 6, 5];
  const smallHoneycomb = [3, 4, 5, 4, 3];
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        delay: 0.3,
      },
    },
  };
  const honeycomb =
    isLargeScreen || isXLargeScreen ? fullHoneycomb : smallHoneycomb;
  return (
    <Box
      component="section"
      sx={{
        py: { xs: 6, md: 12, lg: 10 },
      }}
    >
      <Container>
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Box
              component={motion.div}
              sx={{ maxWidth: 600 }}
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <Typography
                color="text.primary"
                variant="h1"
                component="h1"
                gutterBottom
              >
                Elevate Your Fitness Game
              </Typography>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{
                  mb: 4,
                  fontSize: { md: "1.25rem" },
                }}
              >
                Your new gym journal. Record hybrid workouts. Apply progressive
                overload. See stats. Designed for passionate gym people.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  href="#contact"
                  fullWidth={isMobileView}
                >
                  Start Your Journey
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  href="#features"
                  fullWidth={isMobileView}
                  endIcon={<ChevronRightIcon />}
                >
                  Explore Features
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              component={motion.div}
              initial="hidden"
              animate="visible"
              variants={imageVariants}
            >
              <HoneycombGrid structure={honeycomb} theme={theme} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default HeroSection;
