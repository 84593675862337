import React, { useEffect } from "react";
import { Typography, Grid, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { getWorkout } from "../../redux/slices/workoutSlice";
import { useSelector, useDispatch } from "react-redux";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import RowingIcon from "@mui/icons-material/Rowing";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import RepeatIcon from "@mui/icons-material/Repeat";
import LayersIcon from "@mui/icons-material/Layers";
import BarChartIcon from "@mui/icons-material/BarChart";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import EqualizerIcon from "@mui/icons-material/Equalizer";

const StatRow = ({ icon: Icon, label, value, valueColor }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingY: theme.spacing(1),
      }}
    >
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Icon
          sx={{
            fontSize: isLargeScreen ? 36 : 28,
            marginRight: theme.spacing(1),
            color: theme.palette.text.primary,
          }}
        />
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: theme.palette.text.secondary }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: valueColor }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const StatsCard = ({ stats }) => {
  const {
    totalMilesRun,
    totalMetersRowed,
    maxWeightLifted,
    totalReps,
    totalSets,
    totalVolume,
    avgIntensity,
    avgRepsPerSet,
  } = stats;

  const theme = useTheme();
  const { workout_id } = useSelector((state) => state.workoutSlice);
  const { token } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (workout_id) {
      dispatch(getWorkout({ token, workout_id }));
    }
  }, [workout_id, dispatch, token]);

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ width: "100%", padding: theme.spacing(2) }}
    >
      <StatRow
        icon={BarChartIcon}
        label="Total Volume"
        value={`${totalVolume.toLocaleString()} lbs`}
        valueColor={theme.palette.info.main}
      />
      <StatRow
        icon={WhatshotIcon}
        label="Avg Intensity"
        value={avgIntensity}
        valueColor={theme.palette.primary.main}
      />
      <StatRow
        icon={MilitaryTechIcon}
        label="Max Weight"
        value={`${maxWeightLifted} lbs`}
        valueColor={theme.palette.error.main}
      />
      <StatRow
        icon={RepeatIcon}
        label="Total Reps"
        value={totalReps}
        valueColor={theme.palette.warning.main}
      />
      <StatRow
        icon={LayersIcon}
        label="Total Sets"
        value={totalSets}
        valueColor={theme.palette.warning.main}
      />

      <StatRow
        icon={EqualizerIcon}
        label="Avg Reps/Set"
        value={avgRepsPerSet}
        valueColor={theme.palette.secondary.main}
      />
      <StatRow
        icon={DirectionsRunIcon}
        label="Miles Run"
        value={`${totalMilesRun} mi`}
        valueColor={theme.palette.success.main}
      />
      <StatRow
        icon={RowingIcon}
        label="Meters Rowed"
        value={`${totalMetersRowed} m`}
        valueColor={theme.palette.info.main}
      />
    </Grid>
  );
};

export default StatsCard;
