import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CallToAction = () => {
  const navigate = useNavigate();
  return (
    <Box
      id="contact"
      component="section"
      sx={{
        py: { xs: 12, md: 12, lg: 16 },
      }}
    >
      <Container>
        <Box
          sx={{
            textAlign: "center",
            maxWidth: 600,
            mx: "auto",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            color="text.primary"
            gutterBottom
          >
            Ready to Elevate Your Fitness?
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: 4, fontSize: { md: "1.25rem" } }}
          >
            My goal is to create a way to record workouts with the least amount
            of effort. When I go to the gym I want to enjoy the workout, not
            focus on entering data. Weightliftr is here to solve the problem.
            Join us to be apart of the journey.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              gap: 2,
              maxWidth: 400,
              mx: "auto",
            }}
          >
            <Button
              onClick={() => navigate("/register")}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              Join the Beta
            </Button>
            <Button variant="outlined" color="primary" size="large" fullWidth>
              Watch Demo
            </Button>
          </Box>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ display: "block", mt: 2 }}
          >
            Create a free account today.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default CallToAction;
