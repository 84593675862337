import { createTheme } from "@mui/material/styles";

export const themeConfig = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#4a62ed", // Vibrant Blue
      contrastText: "#FFFFFF", // Text on Primary
    },
    secondary: {
      main: "#E0E0E0", // Light Gray
      contrastText: "#000000", // Text on Secondary
    },
    tertiary: {
      main: "#5b05f6",
      contrastText: "#FFFFFF", // Text on Tertiary
    },
    background: {
      default: "#121212", // Dark background
      paper: "#1E1E1E", // Surface color
    },
    text: {
      primary: "#E0E0E0", // Light Gray
      secondary: "#B0B0B0", // Mid Gray
      disabled: "#8A8A8A", // Dim Gray
    },
    error: {
      main: "#CF6679", // Soft Red
      contrastText: "#000000", // Text on Error
    },
    success: {
      main: "#5cb85c",
    },
    divider: "#373737", // Divider color
  },
  shadows: [
    "none",
    "0px 1px 3px rgba(0, 0, 0, 0.05)", // Example shadows with elevation overlay
    "0px 1px 2px rgba(0, 0, 0, 0.07)",
    // ... Add higher levels of elevation
  ],
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.2,
      "@media (min-width:600px)": {
        fontSize: "3.75rem",
      },
      "@media (min-width:900px)": {
        fontSize: "4.5rem",
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.5rem",
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.25rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },
});

// import { createTheme } from "@mui/material/styles";

// export const themeConfig = createTheme({
//   palette: {
//     mode: "dark",
//     primary: {
//       main: "#000000", // Pure Black
//       contrastText: "#FFFFFF", // White text on black
//     },
//     secondary: {
//       main: "#333333", // Dark Gray
//       contrastText: "#FFFFFF", // White text on dark gray
//     },
//     tertiary: {
//       main: "#5b05f6",
//       contrastText: "#FFFFFF", // Text on Tertiary
//     },
//     background: {
//       default: "#FFFFFF", // White background
//       paper: "#F5F5F5", // Light gray surface
//     },
//     text: {
//       primary: "#000000", // Black
//       secondary: "#333333", // Dark Gray
//       disabled: "#888888", // Mid Gray
//       contrastText: "#FFFFFF", // White text on black
//     },
//     error: {
//       main: "#000000", // Black (for errors)
//       contrastText: "#FFFFFF", // White text on black
//     },
//     divider: "#DDDDDD", // Light Gray divider
//   },
//   typography: {
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     h1: {
//       fontWeight: 700,
//     },
//     h2: {
//       fontWeight: 600,
//     },
//     button: {
//       textTransform: "none",
//       fontWeight: 500,
//     },
//   },
//   shape: {
//     borderRadius: 4,
//   },
//   shadows: [
//     "none",
//     "0px 1px 3px rgba(0, 0, 0, 0.1)",
//     "0px 2px 4px rgba(0, 0, 0, 0.1)",
//     "0px 3px 6px rgba(0, 0, 0, 0.1)",
//     // ... Add more shadow levels as needed
//   ],
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: 4,
//         },
//         contained: {
//           boxShadow: "none",
//           "&:hover": {
//             boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//           },
//         },
//       },
//     },
//   },
// });
