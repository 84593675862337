import React from "react";
import { Box, useTheme } from "@mui/material";

interface StyledBoxProps {
  children: React.ReactNode;
  bgcolor?: string;
  borderRadius?: number;
  p?: number;
  boxShadow?: string;
  maxWidth?: number | string;
  height?: number | string;
  mx?: number | string;
  my?: number | string;
  gradientStart?: string;
  gradientEnd?: string;
}

const StyledBox: React.FC<StyledBoxProps> = ({
  children,
  bgcolor = "background.paper",
  borderRadius = 4,
  py = 4,
  px = 4,
  boxShadow = "0 8px 32px rgba(0, 0, 0, 0.2)",
  width = 300,
  height,
  mx = "auto",
  my = 4,
  gradientStart = "#FF5722",
  gradientEnd,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Box
      {...props}
      sx={{
        color: theme.palette.text.primary,
        bgcolor,
        borderRadius,
        px,
        py,
        boxShadow,
        width,
        height,
        mx,
        my,
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "5px",
          background: `linear-gradient(90deg, ${gradientStart} 0%, ${
            gradientEnd || theme.palette.tertiary?.main || "#FFC107"
          } 100%)`,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default StyledBox;
