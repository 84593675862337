import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RowingIcon from "@mui/icons-material/Rowing";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { FitnessCenter as FitnessCenterIcon } from "@mui/icons-material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { getExerciseList } from "../../redux/slices/exerciseSlice";
import ExerciseStatsChart from "../Account/ExerciseStats/ExerciseWeightChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IncrementButton from "../Exercise/IncrementButton";
import DecrementButton from "../Exercise/DecrementButon";
import { AnimatePresence } from "framer-motion";

const exerciseTypes = ["standard", "row", "run"];
const runDistanceTypes = ["Meter", "Kilometer", "Mile"];

const Exercise = ({
  addExercise,
  addRow,
  addRun,
  workoutActivities,
  isEdit,
  activityForEdit,
  handleEditActivity,
  isPublic = false,
}) => {
  const dispatch = useDispatch();
  const { exercises, exerciseObjects } = useSelector(
    (state) => state.exerciseSlice
  );
  const { token } = useSelector((state) => state.authSlice);
  const [reps, setReps] = useState("");
  const [sets, setSets] = useState("");
  const [name, setName] = useState("");
  const [weight, setWeight] = useState("");
  const [rowDistance, setRowDistance] = useState("");
  const [rowTime, setRowTime] = useState("");
  const [runTime, setRunTime] = useState("");
  const [exerciseUnit, setExerciseUnit] = useState(0);
  const [runDistanceType, setRunDistanceType] = useState(2);
  const [runDistance, setRunDistance] = useState("");
  const [exerciseTypeIndex, setExerciseTypeIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [addActivityMemory, setAddActivityMemory] = useState([]);
  const [activityMemoryIndex, setActivityMemoryIndex] = useState(-1);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [showExerciseHistory, setShowExerciseHistory] = useState(false);

  useEffect(() => {
    if (workoutActivities && workoutActivities.length > 0) {
      setAddActivityMemory([...workoutActivities]);
    }
  }, [workoutActivities]);

  useEffect(() => {
    if (isEdit && activityForEdit) {
      switch (activityForEdit.type) {
        case "exercise":
          setExerciseTypeIndex(0);
          setReps(activityForEdit.data.reps);
          setName(activityForEdit.data.name);
          setWeight(activityForEdit.data.weight);
          setSets(activityForEdit.data.sets);
          break;
        case "row":
          setExerciseTypeIndex(1);
          setRowDistance(activityForEdit.data.distance);
          setRowTime(activityForEdit.data.time);
          break;
        case "run":
          setExerciseTypeIndex(2);
          setRunDistanceType(activityForEdit.data.distance_type);
          setRunTime(activityForEdit.data.time);
          setRunDistance(activityForEdit.data.distance);
          break;
        default:
          console.log("is edit set state not handled");
          break;
      }
    }

    if (!isEdit) {
      clearFields();
    }
  }, [isEdit, activityForEdit]);

  useEffect(() => {
    if (token) {
      dispatch(getExerciseList({ token: token }));
    }
  }, [dispatch, token]);

  const handleShowAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 3000);
  };

  const handleAddExercise = () => {
    switch (exerciseTypes[exerciseTypeIndex]) {
      case "standard":
        if (reps === "" || sets === "" || name === "" || weight === "") {
          handleShowAlert("Missing exercise information");
          break;
        }
        addExercise({ reps, sets, name, weight, exerciseUnit });
        setAddActivityMemory([
          ...addActivityMemory,
          {
            type: "exercise",
            data: { reps, sets, name, weight, exerciseUnit },
          },
        ]);
        break;
      case "row":
        if (rowTime === "" || rowDistance === "") {
          handleShowAlert("Missing row information");
          break;
        }
        addRow({ rowDistance, rowTime });
        break;
      case "run":
        if (runDistance === "" || runTime === "") {
          handleShowAlert("Missing run information");
          break;
        }
        addRun({
          runDistance,
          runTime,
          runDistanceType: runDistanceTypes[runDistanceType],
        });
        break;
      default:
        console.log(
          `handleAddExercise not handled ${exerciseTypes[exerciseTypeIndex]}`
        );
    }

    clearFields();
  };

  const checkRepsSetsAndWeight = (reps, sets, weight) => {
    const repsInt = parseInt(reps);
    const setsInt = parseInt(sets);
    const weightInt = parseInt(weight);

    if (isNaN(repsInt) || isNaN(setsInt) || isNaN(weightInt)) {
      return false; // Ensure all inputs are valid numbers
    }

    if (repsInt <= 0 || repsInt > 1000) {
      return false; // Reps must be between 1 and 1000
    }

    if (setsInt <= 0 || setsInt > 2000) {
      return false; // Sets must be between 1 and 2000
    }

    if (weightInt <= 0 || weightInt > 2000) {
      return false; // Weight must be between 1 and 2000
    }

    return true; // All values are valid
  };

  const handleEdit = () => {
    switch (exerciseTypes[exerciseTypeIndex]) {
      case "standard":
        if (reps === "" || sets === "" || name === "" || weight === "") {
          handleShowAlert("Missing exercise information");
          break;
        }
        if (!checkRepsSetsAndWeight(reps, sets, weight)) {
          handleShowAlert(
            "Data exceeds realistic limits. Contact us if you think otherwise : )"
          );
        }
        handleEditActivity({
          type: "exercise",
          data: {
            reps,
            sets,
            name,
            weight,
            exerciseUnit,
            exercise_id: activityForEdit.data.exercise_id,
          },
        });
        // setAddActivityMemory([
        //   ...addActivityMemory,
        //   {
        //     type: "exercise",
        //     data: { reps, sets, name, weight, exerciseUnit },
        //   },
        // ]);
        break;
      case "row":
        if (rowTime === "" || rowDistance === "") {
          handleShowAlert("Missing row information");
          break;
        }
        handleEditActivity({
          type: "row",
          data: {
            ...activityForEdit.data,
            distance: rowDistance,
            time: rowTime,
          },
        });
        break;
      case "run":
        if (runDistance === "" || runTime === "") {
          handleShowAlert("Missing run information");
          break;
        }
        handleEditActivity({
          type: "run",
          data: {
            ...activityForEdit.data,
            distance: runDistance,
            time: runTime,
            runDistanceType: runDistanceTypes[runDistanceType],
          },
        });
        break;
      default:
        console.log(
          `handleAddExercise not handled ${exerciseTypes[exerciseTypeIndex]}`
        );
    }
    clearFields();
  };

  const clearFields = () => {
    setReps("");
    setSets("");
    setName("");
    setWeight("");
    setRowDistance("");
    setRowTime("");
    setRunTime("");
    setRunDistance("");
    setShowExerciseHistory(false);
    setDebouncedValue("");
  };

  const handlePopulateFromMemory = () => {
    switch (exerciseTypes[exerciseTypeIndex]) {
      case "standard":
        const standardExercises = addActivityMemory.filter(
          (activity) => activity.type === "exercise"
        );

        let exerciseToAdd = null;
        if (activityMemoryIndex <= 0) {
          exerciseToAdd = standardExercises[standardExercises.length - 1].data;
        } else {
          exerciseToAdd = standardExercises[activityMemoryIndex - 1].data;
        }

        setExerciseUnit(exerciseToAdd.weight_unit);
        setSets(exerciseToAdd.sets);
        setName(exerciseToAdd.name);
        setWeight(exerciseToAdd.weight);
        setReps(exerciseToAdd.reps);
        if (activityMemoryIndex <= 0) {
          setActivityMemoryIndex(standardExercises.length - 1);
        } else {
          setActivityMemoryIndex(activityMemoryIndex - 1);
        }
        break;
      default:
        console.log("handlePopulateFromMemory not handled");
    }
  };

  const changeExerciseType = () => {
    if (exerciseTypeIndex + 1 === exerciseTypes.length) {
      setExerciseTypeIndex(0);
    } else {
      setExerciseTypeIndex(exerciseTypeIndex + 1);
    }
  };

  const handleSetRunDistanceType = () => {
    if (runDistanceType === runDistanceTypes.length - 1) {
      setRunDistanceType(0);
    } else {
      const updatedType = runDistanceType + 1;
      setRunDistanceType(updatedType);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(name);
      if (name) {
        console.log("Triggered event after pause:", name);
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [name]);

  const handleNameChange = (value) => {
    if (exerciseObjects && exerciseObjects.hasOwnProperty(value)) {
      const { weight, reps, sets } = exerciseObjects[value].recentStats;
      setWeight(weight);
      setReps(reps);
      setSets(sets);
    }

    if (value === null) {
      setName("");
      setWeight("");
      setReps("");
      setSets("");
    }
    setName(value);
  };

  const handleSetWeight = (value) => {
    setWeight(value);
  };

  return (
    <motion.div
      key={exerciseTypes[exerciseTypeIndex]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      layout
    >
      {exerciseTypes[exerciseTypeIndex] === "standard" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "16px",
          }}
        >
          {debouncedValue && showExerciseHistory ? (
            <motion.div
              key={debouncedValue}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
              style={{ height: "100px" }}
            >
              <ExerciseStatsChart
                showMaxWeight={false}
                exercise_name={debouncedValue}
                height={"100px"}
                padding={"4px 0px"}
                dateFormat={"D-DD"}
              />
            </motion.div>
          ) : null}
          {!showExerciseHistory ? (
            <Box
              layout
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  component={motion.div}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "2px",
                  }}
                  key={exerciseTypeIndex}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                >
                  <FitnessCenterIcon
                    sx={{ paddingBottom: "4px", paddingLeft: "4px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <motion.div
                    key={debouncedValue}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                  >
                    {!isPublic && debouncedValue && !showExerciseHistory ? (
                      <IconButton
                        sx={{ paddingBottom: "16px" }}
                        onClick={() => setShowExerciseHistory(true)}
                      >
                        <ShowChartIcon />
                      </IconButton>
                    ) : null}
                  </motion.div>

                  <IconButton
                    sx={{ paddingBottom: "16px" }}
                    onClick={() => setExerciseUnit(exerciseUnit === 0 ? 1 : 0)}
                  >
                    <Typography>{exerciseUnit === 0 ? "LB" : "KG"}</Typography>
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Autocomplete
                  value={name}
                  onChange={(e, newValue) => handleNameChange(newValue)}
                  options={exercises ? exercises : []}
                  freeSolo
                  onInputChange={(event, newInputValue) => {
                    handleNameChange(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "280px" }}
                      placeholder="Exercise"
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AnimatePresence>
                  <TextField
                    type="number"
                    sx={{ width: "125px" }}
                    placeholder="Weight"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "16px",
                    }}
                  >
                    <IncrementButton
                      weight={weight}
                      handleIncrementWeight={handleSetWeight}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "8px",
                      }}
                    ></Box>
                    <DecrementButton
                      weight={weight}
                      handleDecrementWeight={handleSetWeight}
                    />
                  </Box>
                </AnimatePresence>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  sx={{ width: "125px" }}
                  placeholder="Reps"
                  type={"number"}
                  value={reps}
                  onChange={(e) => setReps(e.target.value)}
                />
                <Typography
                  sx={{ paddingLeft: "8px", paddingRight: "8px" }}
                  variant="body1"
                >
                  x
                </Typography>
                <TextField
                  sx={{ width: "125px" }}
                  placeholder="Sets"
                  type="number"
                  value={sets}
                  onChange={(e) => setSets(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                }}
              ></Box>
            </Box>
          ) : (
            <Box>
              <IconButton
                sx={{ paddingBottom: "16px" }}
                onClick={() => setShowExerciseHistory(false)}
              >
                <DashboardCustomizeIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      ) : exerciseTypes[exerciseTypeIndex] === "row" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              paddingBottom: "16px",
              paddingLeft: "16px",
            }}
          >
            <RowingIcon />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              sx={{ width: "125px" }}
              placeholder="Meters"
              value={rowDistance}
              onChange={(e) => setRowDistance(e.target.value)}
            />
            <Typography
              sx={{ paddingLeft: "8px", paddingRight: "8px" }}
              variant="body1"
            >
              @
            </Typography>
            <TextField
              sx={{ width: "125px" }}
              placeholder="Time"
              value={rowTime}
              onChange={(e) => setRowTime(e.target.value)}
            />
          </Box>
        </Box>
      ) : exerciseTypes[exerciseTypeIndex] === "run" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                paddingLeft: "16px",
                paddingBottom: "16px",
              }}
            >
              <DirectionsRunIcon />
            </Box>

            <IconButton
              sx={{ paddingBottom: "16px" }}
              onClick={handleSetRunDistanceType}
            >
              <Typography>{runDistanceTypes[runDistanceType]}</Typography>
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              sx={{ width: "120px" }}
              placeholder={runDistanceTypes[runDistanceType]}
              value={runDistance}
              onChange={(e) => setRunDistance(e.target.value)}
            />
            <Typography
              sx={{ paddingLeft: "8px", paddingRight: "8px" }}
              variant="body1"
            >
              @
            </Typography>
            <TextField
              sx={{ width: "120px" }}
              placeholder="Time"
              value={runTime}
              onChange={(e) => setRunTime(e.target.value)}
            />
          </Box>
        </Box>
      ) : null}
      {!showExerciseHistory ? (
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          sx={{
            display: "flex",
            justifyContent:
              exerciseTypes[exerciseTypeIndex] === "standard"
                ? "space-between"
                : "flex-end",
            paddingTop: "8px",
          }}
        >
          {exerciseTypes[exerciseTypeIndex] === "standard" && !isEdit ? (
            <IconButton onClick={handlePopulateFromMemory}>
              <img src="/images/brain.png" alt="Brain" width="24" height="24" />
            </IconButton>
          ) : null}
          {!isEdit ? (
            <IconButton onClick={changeExerciseType}>
              <ChangeCircleIcon />
            </IconButton>
          ) : null}
        </Box>
      ) : null}

      {!showExerciseHistory ? (
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={isEdit ? handleEdit : handleAddExercise}>
            {isEdit ? (
              <SaveAsIcon color="text.secondary" />
            ) : (
              <ControlPointIcon color="text.secondary" />
            )}
          </IconButton>
        </Box>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        key={"topcenter"}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default Exercise;
