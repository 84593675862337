import authSlice from "./slices/authSlice";
import profileSlice from "./slices/profileSlice";
import workoutSlice from "./slices/workoutSlice";
import exerciseSlice from "./slices/exerciseSlice";

const rootReducer = {
  profileSlice: profileSlice.reducer,
  authSlice: authSlice.reducer,
  workoutSlice: workoutSlice.reducer,
  exerciseSlice: exerciseSlice.reducer,
};

export default rootReducer;
