import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ExerciseStatsChart = ({
  exercise_name,
  height = "300px",
  padding = "16px",
  dateFormat = "MMM D, YYYY",
}) => {
  const theme = useTheme();
  const { token } = useSelector((state) => state.authSlice);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const handleGetExerciseStats = async () => {
      const response = await axios.post(
        `/api/account/getExerciseStats`,
        {
          exercise_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const labels = response.data
        ? response.data.stats.weightData.map((stats) =>
            dayjs(stats.created_at).format("MMM D, YYYY")
          )
        : [];

      const weights = response.data
        ? response.data.stats.weightData.map((stats) => parseInt(stats.weight))
        : [];

      const data = {
        labels, // X-axis labels
        datasets: [
          {
            label: `${exercise_name} Set Weight`,
            data: weights, // Y-axis data
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            tension: 0.3, // For smooth curve
            pointBackgroundColor: theme.palette.primary.dark,
          },
        ],
      };
      setChartData(data);
    };

    handleGetExerciseStats();
  }, [token, exercise_name, theme.palette.primary]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: theme.palette.text.primary,
        },
        grid: {
          color: theme.palette.divider,
        },
      },
      y: {
        ticks: {
          color: theme.palette.text.primary,
        },
        grid: {
          color: theme.palette.divider,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          color: theme.palette.text.primary,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <Box sx={{ padding: padding, height: height }}>
      {chartData ? (
        <Line options={options} data={chartData} />
      ) : (
        <Typography variant="body1">Loading chart...</Typography>
      )}
    </Box>
  );
};

export default ExerciseStatsChart;
