import { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  FitnessCenter as FitnessCenterIcon,
} from "@mui/icons-material";
import CallToAction from "./components/CallToAction";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeaturesSection";
import Footer from "./components/Footer";
import PricingSection from "./components/PricingSection";
import { useNavigate } from "react-router-dom";
import { postAnalytics } from "../../utils/post_analytics";

export default function LandingPage() {
  useEffect(() => {
    postAnalytics("landing-page", ["page-load"]);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <HeroSection />
        <FeaturesSection />
        {/* <TestimonialsSection /> */}
        <PricingSection />
        <CallToAction />
      </Box>
      <Footer />
    </Box>
  );
}

function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { label: "Features", href: "#features" },
    { label: "Pricing", href: "#pricing" },
    { label: "Contact", href: "#contact" },
  ];

  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={1}
      sx={{ bgcolor: "background.paper" }}
    >
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <FitnessCenterIcon
              sx={{ color: "primary.main", fontSize: 32, mr: 1 }}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "primary.main" }}
            >
              Weightliftr
            </Typography>
          </Box>

          {!isMobile && (
            <>
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                {menuItems.map((item) => (
                  <Button
                    key={item.label}
                    href={item.href}
                    color="inherit"
                    sx={{ mx: 1 }}
                  >
                    {item.label}
                  </Button>
                ))}
                <Button
                  sx={{ mx: 1 }}
                  color="inherit"
                  key="blog"
                  onClick={() => navigate("/blog")}
                >
                  Blog
                </Button>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={() => navigate("/login")}
                  color="inherit"
                  sx={{ mr: 2 }}
                >
                  Log in
                </Button>
                <Button href="#contact" variant="contained" color="primary">
                  Start Lifting
                </Button>
              </Box>
            </>
          )}

          {isMobile && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuToggle}
              sx={{ ml: "auto" }}
            >
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
        </Toolbar>
      </Container>

      <Drawer
        anchor="right"
        open={isMenuOpen && isMobile}
        onClose={handleMenuToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: "70%",
            maxWidth: 300,
            boxSizing: "border-box",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleMenuToggle}>
              <CloseIcon />
            </IconButton>
          </Box>

          <List>
            {menuItems.map((item) => (
              <ListItem key={item.label} disablePadding>
                <ListItemButton href={item.href} onClick={handleMenuToggle}>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  onClick={() => navigate("/blog")}
                  primary="Blog"
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ my: 2 }} />
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  onClick={() => navigate("/login")}
                  primary="Log in"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <Button
                href={"#contact"}
                onClick={handleMenuToggle}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 1 }}
              >
                Start Lifting
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
}
