import { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import {
  Box,
  Paper,
  Typography,
  Container,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid,
  Chip,
  Divider,
  Avatar,
  Button,
  InputAdornment,
  TextField,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CloseIcon from "@mui/icons-material/Close";

// Sample blog metadata - in a real app, this would come from your backend
const blogMetadata = {
  "/blogs/bumstead-bulking-guide.md": {
    title: "Chris Bumstead's Ultimate Bulking Guide",
    excerpt:
      "Learn how to bulk like a pro with these simple, experience-based tips from Mr. Olympia Chris Bumstead—covering nutrition, training, supplements, and mindset.",
    author: "Will Jones",
    date: "April 1, 2025",
    category: "Muscle Building",
    readTime: "7 min read",
    image: "/images/blogs/bumstead-bulking-guide/chris1.png",
    objectFit: "cover",
    objectPosition: "top center",
  },
  "/blogs/volume-is-the-truth.md": {
    title: "Volume is the Truth",
    excerpt:
      "Lifting Heavy vs. High Reps: Why Volume is the Best Metric for Progress",
    author: "Will Jones",
    date: "March 21, 2025",
    category: "Strength Training",
    readTime: "5 min read",
    image: "/images/blogs/volume-is-the-truth/rowing.jpg",
    objectFit: "cover",
    objectPosition: "top center",
  },
  "/blogs/zero-to-pullup.md": {
    title: "Zero to Pull-Up: A Complete Guide",
    excerpt:
      "Learn how to achieve your first pull-up with our comprehensive training program designed for beginners.",
    author: "Will Jones",
    date: "March 7, 2025",
    category: "Strength Training",
    readTime: "5 min read",
    image: "/images/blogs/zero-to-pullup/weighted-pullups.jpg",
    objectFit: "cover",
    objectPosition: "top center",
  },
  "/blogs/data-at-the-right-time.md": {
    title: "Using Data to Optimize Your Workouts",
    excerpt:
      "Discover how tracking the right metrics can dramatically improve your fitness results and prevent plateaus.",
    author: "Will Jones",
    date: "February 28, 2025",
    category: "Fitness Tech",
    readTime: "3 min read",
    image: "/images/blogs/display-is-everything/ui.png",
    objectFit: "cover",
    objectPosition: "center center",
  },
  "/blogs/the-beginning.md": {
    title: "The Weightliftr Journey: How We Started",
    excerpt:
      "The story behind Weightliftr and our mission to make strength training accessible to everyone.",
    author: "Will Jones",
    date: "January 10, 2025",
    category: "Company",
    readTime: "2 min read",
    image: "/images/blogs/the-beginning/hyrox.jpg",
    objectFit: "cover",
    objectPosition: "center center",
  },
};

const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.main,
    fontSize: "1.8rem",
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  "& h2": {
    color: theme.palette.secondary.main,
    fontSize: "1.5rem",
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(3),
  },
  "& h3": {
    fontSize: "1.3rem",
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2.5),
  },
  "& p": {
    fontSize: "1rem",
    lineHeight: 1.7,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  "& ul": {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  "& li": {
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
  },
  "& strong": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  "& img": {
    maxWidth: "100%",
    height: "auto",
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  "& a": {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& blockquote": {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
    fontStyle: "italic",
    margin: theme.spacing(2, 0),
    color: theme.palette.text.secondary,
  },
  "& code": {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontFamily: "monospace",
    fontSize: "0.9rem",
  },
}));

// Custom styled components
const BlogHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(6, 0, 8),
  position: "relative",
  overflow: "hidden",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      "url(https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80) no-repeat center center",
    backgroundSize: "cover",
    opacity: 0.1,
    zIndex: 0,
  },
}));

const FeaturedBlogCard = styled(Card)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  marginBottom: theme.spacing(4),
  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
  },
}));

const BlogCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 25px rgba(0,0,0,0.1)",
  },
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  fontWeight: 500,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  "& .MuiChip-icon": {
    color: theme.palette.primary.contrastText,
  },
}));

const ReadMoreButton = styled(Button)(({ theme }) => ({
  marginTop: "auto",
  alignSelf: "flex-start",
  fontWeight: 600,
}));

const WeightliftrBlog = () => {
  const featuredBlogIndex = 0;
  const [blogs, setBlogs] = useState([]);
  const [expandedBlog, setExpandedBlog] = useState(null);
  const blogRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const blogFiles = [
      "/blogs/bumstead-bulking-guide.md",
      "/blogs/volume-is-the-truth.md",
      "/blogs/zero-to-pullup.md",
      "/blogs/data-at-the-right-time.md",
      "/blogs/the-beginning.md",
    ];

    const fetchBlogs = async () => {
      const blogData = await Promise.all(
        blogFiles.map(async (file) => {
          try {
            const response = await fetch(file);
            if (!response.ok) throw new Error(`Error loading ${file}`);
            const markdown = await response.text();
            // Add metadata to the blog content
            return {
              file,
              markdown,
              ...blogMetadata[file], // Spread the metadata for this file
            };
          } catch (error) {
            console.error(error);
            return {
              file,
              markdown: "Failed to load blog post.",
              ...blogMetadata[file], // Still include metadata even if content fails
            };
          }
        })
      );
      setBlogs(blogData);
    };

    fetchBlogs();
  }, []);

  const handleExpandBlog = (index) => {
    setExpandedBlog(expandedBlog === index ? null : index);
    setTimeout(() => {
      blogRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100); // Small delay ensures state updates before scrolling
  };

  const filteredBlogs = blogs.filter(
    (blog) =>
      blog.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      blog.excerpt?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      blog.category?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const featuredBlog = blogs[0];

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        minHeight: "100vh",
        paddingBottom: "32px",
      }}
    >
      {/* Blog Header */}
      <BlogHeader>
        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 800,
              mb: 2,
              fontSize: { xs: "2.5rem", md: "3.5rem" },
            }}
          >
            Weightliftr Blog
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              mb: 4,
              maxWidth: "800px",
              opacity: 0.9,
            }}
          >
            Experential Advice, training tips, and stories to help you achieve
            your fitness goals.
          </Typography>

          {/* Search Bar */}
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: { xs: "100%", sm: "450px" },
              borderRadius: "50px",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            }}
          >
            <TextField
              fullWidth
              placeholder="Search articles..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: 1.5 }}>
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ ml: 1, flex: 1 }}
            />
          </Paper>
        </Container>
      </BlogHeader>

      <Container maxWidth="lg" sx={{ mt: -4, mb: 8, position: "relative" }}>
        {/* Featured Blog */}
        {featuredBlog && (
          <FeaturedBlogCard onClick={() => handleExpandBlog(featuredBlogIndex)}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="400"
                image={featuredBlog.image}
                alt={featuredBlog.title}
                sx={{
                  objectFit: featuredBlog.objectFit,
                  objectPosition: featuredBlog.objectPosition,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0))",
                  p: 3,
                  pt: 6,
                }}
              >
                <CategoryChip
                  icon={<LocalOfferIcon />}
                  label={featuredBlog.category}
                  size="small"
                  sx={{ mb: 1.5 }}
                />
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{ color: "white", fontWeight: 700, mb: 1 }}
                >
                  {featuredBlog.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "white", opacity: 0.9, mb: 2 }}
                >
                  {featuredBlog.excerpt}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    opacity: 0.8,
                  }}
                >
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      mr: 1,
                      bgcolor: theme.palette.primary.main,
                    }}
                  >
                    <PersonIcon fontSize="small" />
                  </Avatar>
                  <Typography variant="body2" sx={{ mr: 2 }}>
                    {featuredBlog.author}
                  </Typography>
                  <CalendarTodayIcon fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography variant="body2" sx={{ mr: 2 }}>
                    {featuredBlog.date}
                  </Typography>
                  <Typography variant="body2">
                    {featuredBlog.readTime}
                  </Typography>
                </Box>
              </Box>
            </CardActionArea>
          </FeaturedBlogCard>
        )}

        {/* Blog List */}
        {filteredBlogs.length > 0 ? (
          <Grid container spacing={3}>
            {filteredBlogs.map((blog, index) => {
              if (index === 0) return null;
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <BlogCard elevation={2}>
                    <CardActionArea onClick={() => handleExpandBlog(index)}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={blog.image}
                        alt={blog.title}
                        sx={{
                          objectFit: "cover",
                          objectPosition: "top center",
                        }}
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "250px",
                        }}
                      >
                        <Box>
                          <Box sx={{ mb: 1 }}>
                            <CategoryChip
                              icon={<LocalOfferIcon />}
                              label={blog.category}
                              size="small"
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{ fontWeight: 700, mb: 1 }}
                          >
                            {blog.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 2 }}
                          >
                            {blog.excerpt}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            mt: 1,
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 24,
                              height: 24,
                              mr: 1,
                              bgcolor: theme.palette.primary.main,
                            }}
                          >
                            <PersonIcon fontSize="small" />
                          </Avatar>
                          <Typography
                            variant="caption"
                            sx={{ mr: 2, color: "text.secondary" }}
                          >
                            {blog.author}
                          </Typography>
                          <CalendarTodayIcon
                            fontSize="small"
                            sx={{
                              mr: 0.5,
                              fontSize: 16,
                              color: "text.secondary",
                            }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {blog.date}
                          </Typography>
                        </Box>

                        <ReadMoreButton
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleExpandBlog(index);
                          }}
                        >
                          Read More
                        </ReadMoreButton>
                      </CardContent>
                    </CardActionArea>
                  </BlogCard>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Paper sx={{ p: 4, textAlign: "center", mt: 4 }}>
            <Typography variant="h6">
              No articles found matching "{searchTerm}"
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => setSearchTerm("")}
            >
              Clear Search
            </Button>
          </Paper>
        )}

        {/* Expanded Blog View */}
        {expandedBlog !== null && (
          <Box sx={{ paddingTop: "8px" }} ref={blogRef}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 3,
                p: { xs: 2, sm: 4 },
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                  height: "40px",
                }}
              >
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={() => setExpandedBlog(null)}
                  sx={{ position: "absolute", right: 16, top: 16, zIndex: 2 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{ fontWeight: 700, mb: 2, maxWidth: "90%" }}
                >
                  {filteredBlogs[expandedBlog].title}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <CategoryChip
                    icon={<LocalOfferIcon />}
                    label={filteredBlogs[expandedBlog].category}
                    sx={{ mr: 2, mb: isMobile ? 1 : 0 }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 2,
                      mb: isMobile ? 1 : 0,
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        mr: 1,
                        bgcolor: theme.palette.primary.main,
                      }}
                    >
                      <PersonIcon fontSize="small" />
                    </Avatar>
                    <Typography variant="body2" color="text.secondary">
                      {filteredBlogs[expandedBlog].author}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: isMobile ? 1 : 0,
                    }}
                  >
                    <CalendarTodayIcon
                      fontSize="small"
                      sx={{ mr: 0.5, color: "text.secondary" }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {filteredBlogs[expandedBlog].date} •{" "}
                      {filteredBlogs[expandedBlog].readTime}
                    </Typography>
                  </Box>
                </Box>

                <Divider />
              </Box>

              <StyledMarkdown>
                {filteredBlogs[expandedBlog].markdown}
              </StyledMarkdown>
            </Paper>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default WeightliftrBlog;
