import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Checkbox,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWorkout,
  getWorkout,
  clearCurrentWorkout,
  createTemplateFromWorkout,
} from "../../redux/slices/workoutSlice";
import { getProfile } from "../../redux/slices/profileSlice";
import Exercise from "./Exercise";
import WorkoutMenu from "./WorkoutMenu";
import axios from "axios";
import WorkoutTitle from "./WorkoutTitle";
import NavBar from "../NavBar";
import { useParams, useNavigate } from "react-router-dom";
import { getRootDomain } from "../../utils/get_domain";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddIcon from "@mui/icons-material/Add";
import ProgressBar from "../shared/ProgressBar";
import InsightsIcon from "@mui/icons-material/Insights";
import WorkoutStats from "./WorkoutStats";
import WorkoutShareDialog from "../shared/WorkoutShareDialog";

const Workout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const { workout_id } = useParams();
  const { workout } = useSelector((state) => state.workoutSlice);
  const [workoutActivities, setWorkoutActivities] = useState([]);
  const [bottomNavigationState, setBottomNavigationState] = useState(1);
  const [selectedIdForEdit, setSelectedIdForEdit] = useState(null);
  const [activityForEdit, setActivityForEdit] = useState(null);
  const [workoutStats, setWorkoutStats] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");

  useEffect(() => {
    if (workout_id) {
      dispatch(getWorkout({ token, workout_id }));
    }
  }, [workout_id, dispatch, token]);

  useEffect(() => {
    if (workout) {
      setWorkoutActivities(workout.allWorkoutActivities);
      setWorkoutStats(workout.stats);
    }
  }, [workout]);

  const handleDeleteWorkout = async () => {
    try {
      await dispatch(deleteWorkout({ token, workout_id }));
      navigate("/");
    } catch (error) {
      console.error("Failed to delete workout:", error);
    }
  };

  const handleFinishWorkout = () => {
    try {
      dispatch(clearCurrentWorkout());
      dispatch(getProfile({ token }));
      navigate("/");
    } catch (error) {
      console.error("Failed to finish workout:", error);
    }
  };

  const handleAddExerciseToWorkout = async (exercise) => {
    const result = await axios.post(
      `/api/exercise/createExercise`,
      {
        workout_id,
        exercise,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { exercise_id } = result.data;
    const savedExercise = {
      type: "exercise",
      parsed: `${exercise.reps} X ${exercise.sets} ${exercise.name} @ ${
        exercise.weight
      } ${exercise.exerciseUnit === 0 ? "lbs" : "kgs"}`,
      data: {
        exercise_id,
        name: exercise.name,
        sets: exercise.sets,
        reps: exercise.reps,
        weight: exercise.weight,
        weight_unit: exercise.exerciseUnit,
      },
    };
    setWorkoutActivities([...workoutActivities, savedExercise]);
    if (bottomNavigationState === 3) {
      setBottomNavigationState(1);
    }
  };

  const handleAddRowToWorkout = async (row) => {
    const result = await axios.post(
      `/api/exercise/createRow`,
      {
        workout_id,
        row,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { row_id } = result.data;
    const savedRow = {
      parsed: `${row.rowDistance}m row in ${row.rowTime}`,
      type: "row",
      data: {
        row_id,
      },
    };
    setWorkoutActivities([...workoutActivities, savedRow]);
    if (bottomNavigationState === 3) {
      setBottomNavigationState(1);
    }
  };

  const handleAddRunToWorkout = async (run) => {
    const result = await axios.post(
      `/api/exercise/createRun`,
      {
        workout_id,
        run,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { run_id } = result.data;
    const savedRun = {
      parsed: `${run.runDistance} ${run.runDistanceType} run in ${run.runTime}`,
      type: "run",
      data: {
        run_id,
      },
    };
    setWorkoutActivities([...workoutActivities, savedRun]);
    if (bottomNavigationState === 3) {
      setBottomNavigationState(1);
    }
  };

  const handleCheckBoxChange = async (activity, completed, index) => {
    console.log("checkBoxChangeForActivity", activity, completed);

    let copy = [...workoutActivities];
    copy[index] = { ...copy[index], completed };
    setWorkoutActivities(copy);

    const result = await axios.post(
      `/api/exercise/completeActivity`,
      {
        activity,
        completed,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (result.status !== 200) {
      console.log("Error occured with checkbox - reverting checkbox state");
      let copy = [...workoutActivities];
      copy[index] = { ...copy[index], completed: !completed };
      setWorkoutActivities(copy);
    }
  };

  const handleCopyWorkout = () => {
    const parsedStrings = workoutActivities.reduce((workout, activity) => {
      return `${workout}${activity.parsed}\n`;
    }, "");
    navigator.clipboard
      .writeText(parsedStrings)
      .then((data) => {
        console.log("Workout copied to clipboard!", data);
        alert("Workout copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
      });
  };

  const handleCreateTemplateFromWorkout = () => {
    dispatch(createTemplateFromWorkout({ token, workout_id }));
    alert("Template created from workout.");
  };

  const handleCloseConfirmDialog = () => {
    setConfirmModalOpen(false);
    setShareLink(null);
  };

  const handleShareWorkout = async (e) => {
    e.preventDefault();
    const result = await axios.post(
      `/api/workout/createWorkoutPublic`,
      {
        workout_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const publicWorkoutId = result.data.workout_id;
    console.log(result);

    const root = getRootDomain();
    const link = `${root}/shared-workout/${publicWorkoutId}`;
    setShareLink(link);
    setConfirmModalOpen(true);
  };

  const handleBottomNavigationChange = (value) => {
    setBottomNavigationState(value);
    if (selectedIdForEdit) {
      setSelectedIdForEdit(null);
      setActivityForEdit(null);
    }
  };

  const getActivityId = (activity) => {
    switch (activity.type) {
      case "exercise":
        return activity.data.exercise_id;
      case "run":
        return activity.data.run_id;
      case "row":
        return activity.data.row_id;
      default:
        console.log("not handled get activity id");
    }
  };

  const handleEditSelectChange = (activity) => {
    const id = getActivityId(activity);
    if (id === selectedIdForEdit) {
      setSelectedIdForEdit(null);
      setActivityForEdit(null);
    } else {
      setSelectedIdForEdit(id);
      setActivityForEdit(activity);
    }
  };

  const handleEditActivity = async (activity) => {
    await axios.post(
      `/api/exercise/editActivity`,
      {
        workout_id,
        activity,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setActivityForEdit(null);
    setSelectedIdForEdit(null);
    if (activity.type === "exercise") {
      const { data: exercise } = activity;
      const savedExercise = {
        type: "exercise",
        parsed: `${exercise.reps} X ${exercise.sets} ${exercise.name} @ ${
          exercise.weight
        } ${exercise.exerciseUnit === 0 ? "lbs" : "kgs"}`,
        data: {
          ...exercise,
        },
      };
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === exercise.exercise_id) {
          return savedExercise;
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }

    if (activity.type === "run") {
      const { data: run } = activity;
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === run.run_id) {
          return {
            parsed: `${run.distance} ${a.data.distance_type} run in ${run.time}`,
            type: "run",
            data: {
              ...a.data,
              ...run,
            },
          };
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }

    if (activity.type === "row") {
      const { data: row } = activity;
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === activity.data.row_id) {
          console.log("out", row, a);
          return {
            parsed: `${row.distance}m row in ${row.time}`,
            type: "row",
            data: {
              ...a.data,
              ...row,
            },
          };
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%" }}>
          <NavBar />
        </Box>
        <Box
          component={motion.div}
          layout
          transition={{ type: "spring", stiffness: 60, damping: 20 }}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              background: theme.palette.background.paper,
              borderRadius: 4,
              p: 4,
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
              width: 300,
              minHeight: "400px",
              height: "auto",
              mx: "auto",
              my: 4,
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "5px",
                background: `linear-gradient(90deg, #FF5722 0%, ${theme.palette.tertiary.main} 100%)`,
              },
            }}
            component={motion.div}
            layout
          >
            {workout ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: "16px",
                }}
              >
                <Box>
                  <WorkoutTitle />
                </Box>
                <WorkoutMenu
                  handleCopyWorkout={handleCopyWorkout}
                  handleDeleteWorkout={handleDeleteWorkout}
                  handleFinishWorkout={handleFinishWorkout}
                  handleCreateTemplateFromWorkout={
                    handleCreateTemplateFromWorkout
                  }
                  handleShareWorkout={handleShareWorkout}
                />
              </Box>
            ) : null}
            <AnimatePresence>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "left",
                  width: "100%",
                  maxHeight:
                    bottomNavigationState === 0 && selectedIdForEdit
                      ? "64px"
                      : "440px",
                  // overflow: "scroll",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px", // Width of the scrollbar
                    height: "64px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f0f0f0", // Scrollbar track color
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888", // Scrollbar handle color
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#555", // Darker color on hover
                    },
                  },
                }}
              >
                {bottomNavigationState !== 3 && bottomNavigationState !== 4
                  ? workoutActivities.map((activity, index) => {
                      if (
                        selectedIdForEdit &&
                        getActivityId(activity) !== selectedIdForEdit
                      )
                        return null;
                      return (
                        <Box
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            paddingBottom: "4px",
                            paddingTop: "4px",
                          }}
                        >
                          <Typography key={index} variant="body1">
                            {activity.parsed}
                          </Typography>
                          {bottomNavigationState === 2 ? (
                            <Checkbox
                              onChange={(event) =>
                                handleCheckBoxChange(
                                  activity,
                                  event.target.checked,
                                  index
                                )
                              }
                              checked={activity.completed}
                            />
                          ) : null}
                          {bottomNavigationState === 0 ? (
                            <Radio
                              checked={
                                selectedIdForEdit === getActivityId(activity)
                              }
                              onClick={() => handleEditSelectChange(activity)}
                              name={activity.parsed}
                              inputProps={{ "aria-label": activity.parsed }}
                            />
                          ) : null}
                        </Box>
                      );
                    })
                  : null}
              </Box>
              {bottomNavigationState === 4 ? (
                <WorkoutStats stats={workoutStats} />
              ) : null}

              {bottomNavigationState === 3 ? (
                <Exercise
                  addRow={handleAddRowToWorkout}
                  addExercise={handleAddExerciseToWorkout}
                  addRun={handleAddRunToWorkout}
                  workoutActivities={workoutActivities}
                  isEdit={false}
                />
              ) : null}
              {bottomNavigationState === 0 && selectedIdForEdit ? (
                <Exercise
                  handleEditActivity={handleEditActivity}
                  workoutActivities={workoutActivities}
                  activityForEdit={activityForEdit}
                  isEdit={true}
                />
              ) : null}
              {bottomNavigationState === 2 ? (
                <Box>
                  <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
                  {workoutActivities.length > 0 ? (
                    <ProgressBar
                      max={100}
                      value={
                        (workoutActivities.filter((item) => item.completed)
                          .length /
                          workoutActivities.length) *
                        100
                      }
                      color={theme.palette.primary.main}
                      label=""
                      showPercentage={true}
                    />
                  ) : null}
                </Box>
              ) : null}
            </AnimatePresence>
          </Card>
          <BottomNavigation
            showLabels
            value={"walue"}
            onChange={(event, newValue) => {
              handleBottomNavigationChange(newValue);
            }}
            sx={{ borderRadius: "16px", marginTop: "16px" }}
          >
            <BottomNavigationAction
              id="Edit"
              label="Edit"
              icon={<EditIcon />}
            />
            <BottomNavigationAction
              id="View"
              label="View"
              icon={<VisibilityIcon />}
            />
            <BottomNavigationAction
              id="Complete"
              label="Complete"
              icon={<CheckBoxIcon />}
            />
            <BottomNavigationAction id="Add" label="Add" icon={<AddIcon />} />
            <BottomNavigationAction
              id="Stats"
              label="Stats"
              icon={<InsightsIcon />}
            />
          </BottomNavigation>
        </Box>
        <WorkoutShareDialog
          open={confirmModalOpen}
          handleClose={handleCloseConfirmDialog}
          link={shareLink}
        />
      </Box>
    </Box>
  );
};

export default Workout;
