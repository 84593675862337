import React from "react";
import { Box } from "@mui/material";
import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";
import WeeklyStats from "./WeeklyStats";
import RecentWorkout from "./RecentWorkout";
import ExerciseList from "./ExerciseList";
import CreateWorkoutButton from "../shared/CreateWorkoutButton";

const Home = () => {
  const navigate = useNavigate();

  const handleStartWorkoutFlow = async () => {
    navigate("/workout-type-select");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none", // For Chrome, Edge, and Safari
        },
      }} // Prevent default scrollbar
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingTop: "12px",
            paddingRight: "16px",
            paddingBottom: "12px",
          }}
        >
          <CreateWorkoutButton size={60} onClick={handleStartWorkoutFlow} />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WeeklyStats />
          <RecentWorkout />
          <ExerciseList />
        </Box>
      </Box>
    </motion.div>
  );
};

export default Home;
