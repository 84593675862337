import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getExerciseList } from "../../redux/slices/exerciseSlice";
import StyledBox from "../shared/StyledBox";

const ExerciseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { token } = useSelector((state) => state.authSlice);
  const { exercises } = useSelector((state) => state.exerciseSlice);
  useEffect(() => {
    if (token) {
      dispatch(getExerciseList({ token: token }));
    }
  }, [dispatch, token]);

  if (exercises === null) {
    return null;
  }

  return (
    <Box sx={{ padding: "12px" }}>
      <StyledBox my={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ paddingBottom: "16px" }} variant="h6">
            Your Exercises
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {exercises.length > 0 ? (
            exercises.map((exercise) => {
              return (
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/stats/${exercise}`)}
                  sx={{
                    textTransform: "none",
                    marginBottom: "8px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "8px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography sx={{ color: theme.palette.text.primary }}>
                    {exercise}
                  </Typography>
                  <ArrowCircleRightIcon />
                </Button>
              );
            })
          ) : (
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                textAlign: "center",
                padding: "16px",
              }}
            >
              Complete a workout to see your exercises here!
            </Typography>
          )}
        </Box>
      </StyledBox>
    </Box>
  );
};

export default ExerciseList;
