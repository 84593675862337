import React from "react";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Stack,
  Divider,
} from "@mui/material";
import {
  TrendingUp,
  TrendingDown,
  CalendarMonth,
  EmojiEvents,
  Speed,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import StyledBox from "../../shared/StyledBox";
import ExerciseWeightChartCard from "./ExerciseWeightChartCard";
const ExerciseStatsDashboard = ({
  exerciseName,
  maxWeight,
  estimatedOneRepMax,
  percentChange,
  frequency,
  chartData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        maxWidth: "80%",
        mx: "auto",
        p: 2,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <StyledBox
        width="100%"
        gradientStart={theme.palette.primary.main}
        gradientEnd={theme.palette.secondary.main}
        py={isMobile ? 3 : 4}
        px={isMobile ? 2 : 4}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold" }}>
            Performance Metrics for {exerciseName}
          </Typography>

          {isMobile ? (
            <MobileStatsView
              percentChange={percentChange}
              frequency={frequency}
              maxWeight={maxWeight}
              estimatedOneRepMax={estimatedOneRepMax}
            />
          ) : (
            <DesktopStatsView
              percentChange={percentChange}
              frequency={frequency}
              maxWeight={maxWeight}
              estimatedOneRepMax={estimatedOneRepMax}
            />
          )}

          <Divider sx={{ my: 4 }} />

          <Box
            sx={{
              height: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.default",
              borderRadius: 2,
              mt: 2,
            }}
          >
            {chartData ? (
              <ExerciseWeightChartCard
                exercise_name={exerciseName}
                chartData={chartData}
              />
            ) : null}
          </Box>
        </motion.div>
      </StyledBox>
    </Box>
  );
};

const DesktopStatsView = ({
  percentChange,
  frequency,
  maxWeight,
  estimatedOneRepMax,
}) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div variants={containerVariants} initial="hidden" animate="visible">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <motion.div variants={itemVariants}>
            <StatCard
              title="Increase in Weight"
              value={`${percentChange}%`}
              subtitle="since first recorded set"
              icon={percentChange >= 0 ? <TrendingUp /> : <TrendingDown />}
              color={percentChange >= 0 ? "success" : "error"}
            />
          </motion.div>
        </Grid>

        <Grid item xs={12} md={6}>
          <motion.div variants={itemVariants}>
            <StatCard
              title="Frequency Per Week"
              value={`${frequency}`}
              subtitle="Last 3 weeks"
              icon={<CalendarMonth />}
              color="info"
            />
          </motion.div>
        </Grid>

        <Grid item xs={12} md={6}>
          <motion.div variants={itemVariants}>
            <StatCard
              title="All Time Max Weight"
              value={maxWeight}
              subtitle="LBS for Reps"
              icon={<EmojiEvents />}
              color="warning"
            />
          </motion.div>
        </Grid>

        <Grid item xs={12} md={6}>
          <motion.div variants={itemVariants}>
            <StatCard
              title="Estimated 1 Rep Max"
              value={estimatedOneRepMax}
              subtitle="LBS Based on recent sets"
              icon={<Speed />}
              color="secondary"
            />
          </motion.div>
        </Grid>
      </Grid>
    </motion.div>
  );
};

const MobileStatsView = ({
  percentChange,
  frequency,
  maxWeight,
  estimatedOneRepMax,
}) => {
  console.log("is mobile");

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { x: -10, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      sx={{ width: "300px" }}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <Stack spacing={3}>
        <motion.div variants={itemVariants}>
          <MobileStatRow
            title="Increase in Weight"
            value={`${percentChange}%`}
            subtitle="since first set"
            icon={percentChange >= 0 ? <TrendingUp /> : <TrendingDown />}
            color={percentChange >= 0 ? "success" : "error"}
          />
        </motion.div>

        <Divider />

        <motion.div variants={itemVariants}>
          <MobileStatRow
            title="Frequency Per Week"
            value={frequency}
            subtitle="Last 3 weeks"
            icon={<CalendarMonth />}
            color="info"
          />
        </motion.div>

        <Divider />

        <motion.div variants={itemVariants}>
          <MobileStatRow
            title="All Time Max Weight"
            value={maxWeight}
            subtitle="LBS for Reps"
            icon={<EmojiEvents />}
            color="warning"
          />
        </motion.div>

        <Divider />

        <motion.div variants={itemVariants}>
          <MobileStatRow
            title="Estimated 1 Rep Max"
            value={estimatedOneRepMax}
            subtitle="LBS Based on recent sets"
            icon={<Speed />}
            color="secondary"
          />
        </motion.div>
      </Stack>
    </motion.div>
  );
};

const StatCard = ({ title, value, subtitle, icon, color }) => {
  return (
    <Box
      sx={{
        bgcolor: "background.default",
        p: 2.5,
        borderRadius: 2,
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="overline" color="text.secondary">
            {title}
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: "bold", my: 1 }}>
            {value}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </Box>
        <Box
          sx={{
            bgcolor: `${color}.light`,
            p: 1.5,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ color: `${color}.main`, fontSize: 28 }}>{icon}</Box>
        </Box>
      </Box>
    </Box>
  );
};

const MobileStatRow = ({ title, value, subtitle, icon, color }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          bgcolor: `${color}.light`,
          p: 1.5,
          borderRadius: "50%",
          mr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ color: `${color}.main`, fontSize: 24 }}>{icon}</Box>
      </Box>
      <Box>
        <Typography
          variant="overline"
          color="text.secondary"
          sx={{ fontSize: "0.7rem" }}
        >
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mr: 1 }}>
            {value}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ExerciseStatsDashboard;
