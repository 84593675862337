import * as React from "react";
import { IconButton, Typography, Menu, MenuItem, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveIcon from "@mui/icons-material/Save";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ShareIcon from "@mui/icons-material/Share";
export default function MenuListComposition({
  handleCopyWorkout,
  handleDeleteWorkout,
  handleFinishWorkout,
  handleCreateTemplateFromWorkout,
  handleShareWorkout,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type, e = null) => {
    handleClose();

    switch (type) {
      case "handleFinishWorkout":
        handleFinishWorkout();
        break;
      case "handleCopyWorkout":
        handleCopyWorkout();
        break;
      case "handleDeleteWorkout":
        handleDeleteWorkout();
        break;
      case "handleCreateTemplateFromWorkout":
        handleCreateTemplateFromWorkout();
        break;
      case "handleShareWorkout":
        handleShareWorkout(e);
        break;

      default:
        console.log("handleMenuItemClick type not handled");
    }
  };
  return (
    <Box sx={{ bgColor: "background.paper" }}>
      <IconButton
        size="small"
        id="basic-button"
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={() => handleMenuItemClick("handleFinishWorkout")}
        >
          <Typography sx={{ paddingRight: "24px" }}>Finish Workout</Typography>{" "}
          <SaveIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={() => handleMenuItemClick("handleCopyWorkout")}
        >
          <Typography sx={{ paddingRight: "24px" }}>
            Copy Workout Sets
          </Typography>
          <ContentCopyIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={(e) => handleMenuItemClick("handleShareWorkout", e)}
        >
          <Typography sx={{ paddingRight: "24px" }}>Share Workout</Typography>
          <ShareIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={() => handleMenuItemClick("handleCreateTemplateFromWorkout")}
        >
          <Typography sx={{ paddingRight: "24px" }}>
            Create Workout Template
          </Typography>{" "}
          <NoteAddIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={() => handleMenuItemClick("handleDeleteWorkout")}
        >
          <Typography sx={{ paddingRight: "24px" }}>Delete Workout</Typography>{" "}
          <DeleteIcon />
        </MenuItem>
      </Menu>
    </Box>
  );
}
