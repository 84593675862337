import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { FitnessCenter as FitnessCenterIcon } from "@mui/icons-material";
function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        borderTop: 1,
        borderColor: "divider",
        bgcolor: "background.paper",
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <FitnessCenterIcon
                sx={{ color: "primary.main", fontSize: 32, mr: 1 }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "primary.main" }}
              >
                Weightliftr
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, maxWidth: 350 }}
            >
              Empowering lifters to track, improve, and dominate their fitness
              journey since 2024. Our mission is to help you get more from your
              workout.
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              {["Facebook", "Twitter", "Instagram", "YouTube"].map((social) => (
                <IconButton key={social} size="small" color="inherit">
                  <Box
                    component="span"
                    sx={{
                      width: 24,
                      height: 24,
                      bgcolor: "action.hover",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.75rem",
                    }}
                  >
                    {social.charAt(0)}
                  </Box>
                </IconButton>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Product
                </Typography>
                <List disablePadding>
                  {["Features", "Pricing", "Challenges", "Integrations"].map(
                    (item) => (
                      <ListItem key={item} disablePadding disableGutters>
                        <ListItemButton
                          component="a"
                          href="#"
                          disableGutters
                          sx={{
                            py: 0.5,
                            px: 0,
                            color: "text.secondary",
                            "&:hover": { color: "primary.main" },
                          }}
                        >
                          <Typography variant="body2">{item}</Typography>
                        </ListItemButton>
                      </ListItem>
                    )
                  )}
                </List>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Company
                </Typography>
                <List disablePadding>
                  {["About", "Blog", "Careers", "Contact"].map((item) => (
                    <ListItem key={item} disablePadding disableGutters>
                      <ListItemButton
                        component="a"
                        href="#"
                        disableGutters
                        sx={{
                          py: 0.5,
                          px: 0,
                          color: "text.secondary",
                          "&:hover": { color: "primary.main" },
                        }}
                      >
                        <Typography variant="body2">{item}</Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Resources
                </Typography>
                <List disablePadding>
                  {["Help Center", "API", "Community", "Privacy Policy"].map(
                    (item) => (
                      <ListItem key={item} disablePadding disableGutters>
                        <ListItemButton
                          component="a"
                          href="#"
                          disableGutters
                          sx={{
                            py: 0.5,
                            px: 0,
                            color: "text.secondary",
                            "&:hover": { color: "primary.main" },
                          }}
                        >
                          <Typography variant="body2">{item}</Typography>
                        </ListItemButton>
                      </ListItem>
                    )
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} Weightliftr Inc. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
