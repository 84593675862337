import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Check as CheckIcon } from "@mui/icons-material";

function PricingSection() {
  const navigate = useNavigate();
  const plans = [
    {
      title: "Basic",
      price: "$0",
      description: "Easily record workouts and ",
      features: [
        "Unlimited workout tracking",
        "Basic progress analytics",
        "Create Workout Templates",
        "Access our library of workouts and routines",
      ],
      cta: "Start Lifting",
      popular: false,
    },
    {
      title: "AI Pro (Coming soon)",
      price: "$19.99",
      description: "",
      features: [
        "AI Personal Trainer",
        "Build Routines with AI",
        "All Basic Features",
        "Advanced Analytics and Goal tracking",
        "Priority support",
      ],
      cta: "Go Pro",
      popular: false,
    },
    // {
    //   title: "Personal Trainer (Coming soon)",
    //   price: "$49.99",
    //   description: "Manage your clients with powerful tools.",
    //   features: [
    //     "All Pro Lifter features",
    //     "Client management dashboard",
    //     "Customizable gym challenges",
    //     "Performance reports for clients",
    //     "Branded app experience",
    //   ],
    //   cta: "Contact Sales",
    //   popular: false,
    // },
  ];

  return (
    <Box
      id="pricing"
      component="section"
      sx={{
        py: { xs: 12, md: 12, lg: 16 },
        bgcolor: "grey.50",
      }}
    >
      <Container>
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            sx={{
              borderRadius: 4,
              px: 2,
              py: 0.5,
              mb: 2,
              textTransform: "none",
              pointerEvents: "none",
            }}
          >
            Pricing
          </Button>
          <Typography variant="h2" component="h2" gutterBottom>
            Choose Your Path to Strength
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              maxWidth: 900,
              mx: "auto",
              fontSize: { md: "1.25rem" },
            }}
          >
            Select the plan that fits your goals. All plans include a 14-day
            free trial to experience the full power of Weightliftr.
          </Typography>
        </Box>

        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              sx={{
                transform: plan.popular ? { md: "scale(1.05)" } : "none",
                zIndex: plan.popular ? 1 : 0,
              }}
            >
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  border: plan.popular ? 2 : 1,
                  borderColor: plan.popular ? "primary.main" : "grey.200",
                  boxShadow: plan.popular ? 4 : 1,
                }}
              >
                {plan.popular && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bgcolor: "primary.main",
                      color: "primary.contrastText",
                      px: 2,
                      py: 0.5,
                      borderBottomLeftRadius: 8,
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                    }}
                  >
                    Most Popular
                  </Box>
                )}
                <CardHeader
                  title={plan.title}
                  titleTypographyProps={{ variant: "h5", component: "h3" }}
                  subheader={
                    <Box
                      sx={{ display: "flex", alignItems: "baseline", mt: 1 }}
                    >
                      <Typography
                        variant="h4"
                        component="span"
                        fontWeight="bold"
                      >
                        {plan.price}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        color="text.secondary"
                        sx={{ ml: 0.5 }}
                      >
                        /month
                      </Typography>
                    </Box>
                  }
                  sx={{ pb: 0 }}
                />
                <CardContent sx={{ pt: 1, pb: 2, flexGrow: 1 }}>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {plan.description}
                  </Typography>
                  <List disablePadding>
                    {plan.features.map((feature, i) => (
                      <ListItem
                        key={i}
                        disablePadding
                        disableGutters
                        sx={{ py: 0.5 }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CheckIcon
                            color="primary"
                            fontSize="small"
                            sx={{ mr: 1 }}
                          />
                          <Typography variant="body2">{feature}</Typography>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <Box sx={{ p: 2, pt: 0 }}>
                  <Button
                    onClick={() => navigate("/register")}
                    variant={plan.popular ? "contained" : "outlined"}
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    {plan.cta}
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default PricingSection;
