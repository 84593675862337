import * as React from "react";
import { IconButton, Typography, Menu, MenuItem, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";

export default function WorkoutTemplateMenu({
  handleDeleteWorkoutTemplate,
  handleFinishWorkoutTemplate,
  handleShareWorkout,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type, e = null) => {
    handleClose();
    switch (type) {
      case "handleFinishWorkoutTemplate":
        handleFinishWorkoutTemplate();
        break;
      case "handleDeleteWorkoutTemplate":
        handleDeleteWorkoutTemplate();
        break;
      case "handleShareWorkout":
        handleShareWorkout(e);
        break;
      default:
        console.log("handleMenuItemClick type not handled");
    }
  };
  return (
    <Box>
      <IconButton
        size="small"
        id="basic-button"
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={() => handleMenuItemClick("handleFinishWorkoutTemplate")}
        >
          <Typography sx={{ paddingRight: "24px" }}>Finish Template</Typography>{" "}
          <SaveIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={() => handleMenuItemClick("handleDeleteWorkoutTemplate")}
        >
          <Typography sx={{ paddingRight: "24px" }}>Delete Template</Typography>{" "}
          <DeleteIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          onClick={(e) => handleMenuItemClick("handleShareWorkout", e)}
        >
          <Typography sx={{ paddingRight: "24px" }}>Share Template</Typography>{" "}
          <ShareIcon />
        </MenuItem>
      </Menu>
    </Box>
  );
}
