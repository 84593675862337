import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getExerciseList = createAsyncThunk(
  "exercise/getExerciseList",
  async ({ token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/api/account/listExercises`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const exerciseSlice = createSlice({
  name: "exercise",
  initialState: {
    exercises: null,
    exerciseObjects: null,
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExerciseList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getExerciseList.fulfilled, (state, action) => {
        const { exercises, exerciseObjects } = action.payload;
        state.exercises = exercises;
        state.exerciseObjects = exerciseObjects;
        state.loading = false;
        state.error = false;
      })
      .addCase(getExerciseList.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export default exerciseSlice;
