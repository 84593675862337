import React from "react";
import { Box } from "@mui/material";
import NavBar from "../NavBar";
import { useSelector } from "react-redux";
import Articles from "./components/Articles";
import { motion } from "framer-motion";

const LandingPage = () => {
  const { user } = useSelector((state) => state.profileSlice);
  return (
    <Box
      component={motion.div}
      layout
      transition={{ type: "spring", stiffness: 60, damping: 20 }}
    >
      <NavBar isMenuDisabled={user ? false : true} />
      <Articles />
    </Box>
  );
};

export default LandingPage;
