import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";

function ValidationCodeInput({ handleSubmitValidationCode, disabled = false }) {
  const [code, setCode] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);

  // Initialize refs array
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 6);
  }, []);

  // Auto-submit when all fields are filled
  useEffect(() => {
    if (code.every((digit) => digit !== "") && code.length === 6) {
      handleSubmitValidationCode(code.join(""));
    }
  }, [code, handleSubmitValidationCode]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    // Only accept single characters
    if (value.length > 1) {
      return;
    }

    // Update the code state
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Move to next input if value is entered
    if (value !== "" && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle backspace
    if (e.key === "Backspace") {
      if (code[index] === "" && index > 0) {
        // If current input is empty, focus previous input
        inputRefs.current[index - 1]?.focus();
      } else {
        // Clear current input
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
      }
    }
    // Handle left arrow key
    else if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
    // Handle right arrow key
    else if (e.key === "ArrowRight" && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();

    // Only process if the pasted content looks like a validation code
    if (pastedData.length === 6 && /^[a-zA-Z0-9]*$/.test(pastedData)) {
      const newCode = pastedData.split("");
      setCode(newCode);

      // Focus the last input
      inputRefs.current[5]?.focus();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
        }}
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            variant="outlined"
            inputProps={{
              maxLength: 1,
              inputMode: "numeric",
              pattern: "[0-9]*",
              style: {
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.2rem",
                padding: "4px",
                width: "32px",
                height: "32px",
              },
              "aria-label": `Digit ${index + 1} of verification code`,
            }}
            value={code[index]}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            disabled={disabled}
            sx={{
              width: { xs: "40px", sm: "48px" },
              height: { xs: "48px", sm: "56px" },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "primary.main",
                  borderWidth: 2,
                },
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

ValidationCodeInput.propTypes = {
  handleSubmitValidationCode: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ValidationCodeInput;
