import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Alert,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { validateEmail } from "../utils/account_verify";
import ValidationCodeInput from "./ValidationCodeInput";
import Checkmark from "./shared/AnimatedCheckmark";

const Register = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    if (showValidation) {
      setTimeout(() => {
        setShowResendButton(true);
      }, 10000);
    }
  }, [showValidation]);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  }, [showSuccess, navigate]);

  useEffect(() => {
    if (resendDisabled) {
      setTimeout(() => {
        setResendDisabled(false);
      }, 30000);
    }
  }, [resendDisabled]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(`/api/account/register`, { email, password });
      setIsLoading(false);
      alert("Registration successful. Please check email for validation code.");
      setShowValidation(true);
    } catch (err) {
      setError(
        err.response?.data?.error || "Something went wrong. Please try again."
      );
      setIsLoading(false);
    }
  };

  const handleSubmitValidationCode = async (code) => {
    const result = await axios.post(`/api/account/validate`, { email, code });
    if (result.status === 200) {
      setShowSuccess(true);
    } else {
      setError(result.message);
    }
  };

  const handleResend = async () => {
    await axios.post(`/api/account/register`, {
      email,
      password,
    });
    console.log("result");
    setResendDisabled(true);
  };
  return (
    <Box
      sx={{
        background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Back Button */}
      <Box sx={{ padding: { xs: "8px", sm: "16px" } }}>
        <IconButton
          aria-label="back"
          onClick={() => navigate("/welcome")}
          sx={{
            backgroundColor: theme.palette.background.paper,
            "&:hover": {
              backgroundColor: theme.palette.background.default,
            },
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          flexGrow: 1,
          width: "100%",
          paddingTop: "36px",
        }}
      >
        <Paper
          elevation={6}
          sx={{
            width: "100%",
            maxWidth: "400px",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              padding: "32px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {!showValidation ? (
              <Box sx={{ textAlign: "center" }}>
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontWeight: 700,
                    marginBottom: "8px",
                    color: theme.palette.text.primary,
                  }}
                >
                  Create an Account
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Sign up to get started
                </Typography>
              </Box>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontWeight: 700,
                    marginBottom: "8px",
                    color: theme.palette.text.primary,
                  }}
                >
                  Check Email for Validation Code
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Enter code below
                </Typography>
              </Box>
            )}

            {error && (
              <Alert severity="error" sx={{ width: "100%" }}>
                {error}
              </Alert>
            )}
            {!showValidation ? (
              <form onSubmit={handleRegister} style={{ width: "100%" }}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="email"
                    placeholder="your.email@example.com"
                    InputProps={{
                      sx: { borderRadius: "8px" },
                    }}
                  />

                  <TextField
                    fullWidth
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    autoComplete="new-password"
                    InputProps={{
                      sx: { borderRadius: "8px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isLoading}
                    sx={{
                      borderRadius: "8px",
                      padding: "12px",
                      textTransform: "none",
                      fontWeight: 600,
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>

                  <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                    <Divider sx={{ flexGrow: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{ mx: 2, color: theme.palette.text.secondary }}
                    >
                      or
                    </Typography>
                    <Divider sx={{ flexGrow: 1 }} />
                  </Box>

                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      Already have an account?{" "}
                      <Link
                        component="button"
                        type="button"
                        variant="body2"
                        onClick={() => navigate("/login")}
                        sx={{
                          fontWeight: 600,
                          color: theme.palette.primary.main,
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        Sign In
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </form>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showSuccess ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Checkmark
                      strokeWidth={4}
                      color={theme.palette.success.main}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <ValidationCodeInput
                      handleSubmitValidationCode={handleSubmitValidationCode}
                    />
                    {showResendButton ? (
                      <Button
                        sx={{ marginTop: "8px" }}
                        disabled={resendDisabled}
                        onClick={handleResend}
                      >
                        Resend
                      </Button>
                    ) : null}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Register;
