import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Checkbox,
  BottomNavigation,
  BottomNavigationAction,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkoutPublic,
  createWorkoutCopy,
} from "../../redux/slices/workoutSlice";
import Exercise from "./Exercise";
import WorkoutMenu from "./WorkoutMenu";
import WorkoutTitle from "./WorkoutTitle";
import NavBar from "../NavBar";
import LoginRegisterModal from "../LoginRegisterModal";
import { useParams, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddIcon from "@mui/icons-material/Add";
import WorkoutStats from "./WorkoutStats";
import InsightsIcon from "@mui/icons-material/Insights";

const WorkoutPublic = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const { user } = useSelector((state) => state.profileSlice);
  const { pub_workout_id } = useParams();
  const { workout, workout_id } = useSelector((state) => state.workoutSlice);
  const [workoutActivities, setWorkoutActivities] = useState([]);
  const [workoutStats, setWorkoutStats] = useState(null);

  const [open, setOpen] = useState(false);
  const [bottomNavigationState, setBottomNavigationState] = useState("view");

  const [selectedIdForEdit, setSelectedIdForEdit] = useState(null);
  const [activityForEdit, setActivityForEdit] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (pub_workout_id) {
      if (token && user) {
        dispatch(
          createWorkoutCopy({
            token: token,
            workout_id: pub_workout_id,
            user_id: user.email,
          })
        );
      }
      if (!token) {
        dispatch(
          getWorkoutPublic({
            token: token,
            workout_id: pub_workout_id,
          })
        );
      }
    }
  }, [pub_workout_id, user, dispatch, token]);

  // probably want to just add a add workout template or start your own menu item
  useEffect(() => {
    if (pub_workout_id && workout_id) {
      if (pub_workout_id !== workout_id) {
        navigate(`/workout/${workout_id}`);
      }
    }
  }, [pub_workout_id, workout_id, navigate]);

  useEffect(() => {
    if (workout) {
      const allActivitiesReset = workout.allWorkoutActivities.map(
        (activity) => {
          return {
            ...activity,
            completed: false,
          };
        }
      );
      setWorkoutActivities(allActivitiesReset);
      setWorkoutStats(workout.stats);
    }
  }, [workout]);

  const handleUserActionWithNoToken = () => {
    handleOpen();
  };

  const handleCheckBoxChange = async (activity, completed, index) => {
    let copy = [...workoutActivities];
    copy[index] = { ...copy[index], completed };
    setWorkoutActivities(copy);
  };

  const handleCopyWorkout = () => {
    const parsedStrings = workoutActivities.reduce((workout, activity) => {
      return `${workout}${activity.parsed}\n`;
    }, "");
    navigator.clipboard
      .writeText(parsedStrings)
      .then(() => {
        console.log("Workout copied to clipboard!");
        alert("Workout copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
      });
  };

  const handleBottomNavigationChange = (value) => {
    setBottomNavigationState(value);
    if (selectedIdForEdit) {
      setSelectedIdForEdit(null);
      setActivityForEdit(null);
    }
  };

  const handleEditActivity = async (activity) => {
    // await axios.post(
    //   `/api/exercise/editActivity`,
    //   {
    //     workout_id,
    //     activity,
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    setActivityForEdit(null);
    setSelectedIdForEdit(null);
    if (activity.type === "exercise") {
      const { data: exercise } = activity;
      const savedExercise = {
        type: "exercise",
        parsed: `${exercise.reps} X ${exercise.sets} ${exercise.name} @ ${
          exercise.weight
        } ${exercise.exerciseUnit === 0 ? "lbs" : "kgs"}`,
        data: {
          ...exercise,
        },
      };
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === exercise.exercise_id) {
          return savedExercise;
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }

    if (activity.type === "run") {
      const { data: run } = activity;
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === run.run_id) {
          return {
            parsed: `${run.distance} ${a.data.distance_type} run in ${run.time}`,
            type: "run",
            data: {
              ...a.data,
              ...run,
            },
          };
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }

    if (activity.type === "row") {
      const { data: row } = activity;
      const updatedActivities = workoutActivities.map((a) => {
        if (getActivityId(a) === activity.data.row_id) {
          return {
            parsed: `${row.distance}m row in ${row.time}`,
            type: "row",
            data: {
              ...a.data,
              ...row,
            },
          };
        } else {
          return a;
        }
      });
      setWorkoutActivities([...updatedActivities]);
    }
  };

  const getActivityId = (activity) => {
    switch (activity.type) {
      case "exercise":
        return activity.data.exercise_id;
      case "run":
        return activity.data.run_id;
      case "row":
        return activity.data.row_id;
      default:
        console.log("not handled get activity id");
    }
  };

  const handleEditSelectChange = (activity) => {
    const id = getActivityId(activity);
    if (id === selectedIdForEdit) {
      setSelectedIdForEdit(null);
      setActivityForEdit(null);
    } else {
      setSelectedIdForEdit(id);
      setActivityForEdit(activity);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%" }}>
          <NavBar isMenuDisabled={true} isInstallable={false} />
        </Box>
        <Box
          component={motion.div}
          layout
          transition={{ type: "spring", stiffness: 60, damping: 20 }}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Card
              sx={{
                background: theme.palette.background.paper,
                borderRadius: 4,
                p: 4,
                boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
                width: 300,
                height: "auto",
                mx: "auto",
                my: 4,
                position: "relative",
                overflow: "hidden",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: `linear-gradient(90deg, #FF5722 0%, ${theme.palette.tertiary.main} 100%)`,
                },
              }}
              component={motion.div}
              layout
            >
              <LoginRegisterModal
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
              {workout ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "16px",
                  }}
                >
                  <Box>
                    <WorkoutTitle />
                  </Box>
                  <WorkoutMenu
                    handleCopyWorkout={handleCopyWorkout}
                    handleDeleteWorkout={handleUserActionWithNoToken}
                    handleFinishWorkout={handleUserActionWithNoToken}
                    handleCreateTemplateFromWorkout={
                      handleUserActionWithNoToken
                    }
                    handleShareWorkout={handleUserActionWithNoToken}
                  />
                </Box>
              ) : null}

              <AnimatePresence>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "left",
                    width: "100%",
                    maxHeight:
                      bottomNavigationState === 0 && selectedIdForEdit
                        ? "64px"
                        : "440px",
                    // overflow: "scroll",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px", // Width of the scrollbar
                      height: "64px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f0f0f0", // Scrollbar track color
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#888", // Scrollbar handle color
                      borderRadius: "10px",
                      "&:hover": {
                        background: "#555", // Darker color on hover
                      },
                    },
                  }}
                >
                  {bottomNavigationState !== 3 && bottomNavigationState !== 4
                    ? workoutActivities.map((activity, index) => {
                        if (
                          selectedIdForEdit &&
                          getActivityId(activity) !== selectedIdForEdit
                        )
                          return null;
                        return (
                          <Box
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              paddingBottom: "4px",
                              paddingTop: "4px",
                            }}
                          >
                            <Typography key={index} variant="body1">
                              {activity.parsed}
                            </Typography>
                            {bottomNavigationState === 2 ? (
                              <Checkbox
                                onChange={(event) =>
                                  handleCheckBoxChange(
                                    activity,
                                    event.target.checked,
                                    index
                                  )
                                }
                                checked={activity.completed}
                              />
                            ) : null}
                            {bottomNavigationState === 0 ? (
                              <Radio
                                checked={
                                  selectedIdForEdit === getActivityId(activity)
                                }
                                onClick={() => handleEditSelectChange(activity)}
                                name={activity.parsed}
                                inputProps={{ "aria-label": activity.parsed }}
                              />
                            ) : null}
                          </Box>
                        );
                      })
                    : null}
                </Box>
                {bottomNavigationState === 4 ? (
                  <WorkoutStats stats={workoutStats} />
                ) : null}
                {bottomNavigationState === 3 ? (
                  <Exercise
                    addRow={handleUserActionWithNoToken}
                    addExercise={handleUserActionWithNoToken}
                    addRun={handleUserActionWithNoToken}
                    workoutActivities={workoutActivities}
                    isEdit={false}
                    isPublic={true}
                  />
                ) : null}
                {bottomNavigationState === 0 && selectedIdForEdit ? (
                  <Exercise
                    handleEditActivity={handleEditActivity}
                    workoutActivities={workoutActivities}
                    activityForEdit={activityForEdit}
                    isEdit={true}
                    isPublic={true}
                  />
                ) : null}
              </AnimatePresence>
            </Card>
            <BottomNavigation
              showLabels
              value={"walue"}
              onChange={(event, newValue) => {
                console.log(newValue);
                handleBottomNavigationChange(newValue);
              }}
              sx={{ borderRadius: "16px", marginTop: "16px" }}
            >
              <BottomNavigationAction
                id="Edit"
                label="Edit"
                icon={<EditIcon />}
              />
              <BottomNavigationAction
                id="View"
                label="View"
                icon={<VisibilityIcon />}
              />
              <BottomNavigationAction
                id="Complete"
                label="Complete"
                icon={<CheckBoxIcon />}
              />
              <BottomNavigationAction id="Add" label="Add" icon={<AddIcon />} />
              <BottomNavigationAction
                id="Stats"
                label="Stats"
                icon={<InsightsIcon />}
              />
            </BottomNavigation>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutPublic;
