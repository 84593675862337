import { useState } from "react";
import { Button, Tooltip, Box, useTheme } from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function CreateWorkoutButton({
  onClick,
  disabled = false,
  size = 80,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  return (
    <Tooltip title="New Workout" arrow placement="top">
      <Button
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          position: "relative",
          overflow: "hidden",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderRadius: "50%",
          width: `${size}px`,
          height: `${size}px`,
          minWidth: "unset",
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            transform: "translateY(-3px)",
            boxShadow: "0 6px 25px rgba(0, 0, 0, 0.2)",
          },
          "&:active": {
            transform: "translateY(1px)",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "radial-gradient(circle, transparent 60%, rgba(255,255,255,0.2))",
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.6s ease",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "transform 0.3s ease",
            transform: isHovered ? "scale(1.1)" : "scale(1)",
          }}
        >
          {isHovered ? (
            <AddCircleOutlineIcon sx={{ fontSize: size * 0.4 }} />
          ) : (
            <FitnessCenterIcon sx={{ fontSize: size * 0.4 }} />
          )}
        </Box>
      </Button>
    </Tooltip>
  );
}
