import { Box, Typography, IconButton, ButtonGroup } from "@mui/material";
import { motion } from "framer-motion";
import RowingIcon from "@mui/icons-material/Rowing";
import { FitnessCenter as FitnessCenterIcon } from "@mui/icons-material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AnalyticsIcon from "@mui/icons-material/Analytics";
// import ELRDisplay from "./WeeklyStats/ELRDisplay";
import RunPerDayChart from "./WeeklyStats/RunPerDayChart";
import RowPerDayChart from "./WeeklyStats/RowPerDayChart";
import WeightPerDayChart from "./WeeklyStats/WeightPerDayChart";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import StyledBox from "../shared/StyledBox";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const WeeklyStats = () => {
  const [weeklyStatsType, setWeeklyStatsType] = useState("weights");
  const [totalWeightMoved, setTotalWeightMoved] = useState("");
  const [totalReps, setTotalReps] = useState("");
  const [totalSets, setTotalSets] = useState("");
  const [weightPerDay, setWeightPerDay] = useState("");
  // const [elr, setElr] = useState("");
  const [rowPerDay, setRowPerDay] = useState("");
  const [totalRowDistance, setTotalRowDistance] = useState("");
  const [runPerDay, setRunPerDay] = useState("");
  const [totalRunDistance, setTotalRunDistance] = useState("");
  const { token } = useSelector((state) => state.authSlice);

  useEffect(() => {
    const fetchWeeklyStats = async () => {
      const utcOffset = dayjs().format("Z");
      const result = await axios.post(
        "/api/account/weeklyStats",
        { utcOffset },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("data", result);
      setTotalWeightMoved(result.data.total_weight_moved);
      setTotalReps(result.data.total_reps);
      setTotalSets(result.data.total_sets);
      setTotalRowDistance(result.data.total_row_distance);
      setTotalRunDistance(result.data.total_run_distance);
      setWeightPerDay(result.data.weight_per_day);
      setRowPerDay(result.data.row_per_day);
      setRunPerDay(result.data.run_per_day);
      // setElr(result.data.elr);
    };

    fetchWeeklyStats();
  }, [token]);

  const handleWeeklyStatsTypeChange = (type) => {
    setWeeklyStatsType(type);
  };

  const buttons = [
    <IconButton
      sx={{ marginRight: "4px" }}
      onClick={() => handleWeeklyStatsTypeChange("weights")}
      size="small"
      key="one"
    >
      <FitnessCenterIcon />
    </IconButton>,
    <IconButton
      sx={{ marginRight: "4px" }}
      onClick={() => handleWeeklyStatsTypeChange("runs")}
      size="small"
      key="two"
    >
      <DirectionsRunIcon />
    </IconButton>,
    <IconButton
      sx={{ marginRight: "4px" }}
      onClick={() => handleWeeklyStatsTypeChange("rows")}
      size="small"
      key="three"
    >
      <RowingIcon />
    </IconButton>,
  ];

  const handleShowWeeklyStatsType = (type) => {
    return (
      <motion.div
        key={type}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        {type === "weights" && (
          <>
            <WeightPerDayChart weightPerDay={weightPerDay} />
            <Box sx={{ paddingTop: "16px" }}>
              <Typography variant="body1">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {totalWeightMoved.toLocaleString()} lbs
                </Typography>
                have moved this week.
              </Typography>
              <Typography variant="body1">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {totalSets}
                </Typography>
                total sets this week.
              </Typography>
              <Typography variant="body1">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {totalReps}
                </Typography>
                total reps this week.
              </Typography>
            </Box>
          </>
        )}
        {type === "runs" && (
          <>
            <RunPerDayChart runPerDay={runPerDay} />
            <Typography variant="body1">
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {totalRunDistance}
              </Typography>
              total miles run this week.
            </Typography>
          </>
        )}
        {type === "rows" && (
          <>
            <RowPerDayChart rowPerDay={rowPerDay} />
            <Typography variant="body1">
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {totalRowDistance}
              </Typography>
              total meters rowed this week.
            </Typography>
          </>
        )}
      </motion.div>
    );
  };

  return (
    <StyledBox my={1}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <AnalyticsIcon sx={{ paddingRight: "8px" }} />
          <Typography variant="h6">Weekly Stats</Typography>
        </Box>

        <ButtonGroup size="small" aria-label="Small button group">
          {buttons}
        </ButtonGroup>
      </Box>
      {handleShowWeeklyStatsType(weeklyStatsType)}
    </StyledBox>
  );
};

export default WeeklyStats;
