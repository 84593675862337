import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { motion } from "framer-motion";

export default function IncrementButton({ weight, handleIncrementWeight }) {
  const add5 = () => {
    handleIncrementWeight(parseInt(!weight ? 0 : weight) + 5);
  };

  return (
    <Box
      sx={{
        maxWidth: "125px",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        borderRadius: 2,
      }}
      component={motion.div}
      layout
    >
      <Button
        variant="contained"
        color="primary"
        onClick={add5}
        startIcon={<AddIcon />}
        aria-label="Increment by 5 percent"
        sx={{ width: "125px" }}
      >
        5
      </Button>
    </Box>
  );
}
