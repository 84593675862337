import { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";

export default function PasswordReset() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError("Email is required");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    setEmailError("");
    setIsSubmitting(true);

    await axios.post(`/api/account/passwordReset`, { email });
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          minHeight: "100vh",
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "300px",
            p: isMobile ? 3 : 4,
            borderRadius: 2,
          }}
        >
          {!isSubmitted ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "50%",
                    p: 1,
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <EmailIcon
                    size={24}
                    color={theme.palette.primary.contrastText}
                  />
                </Box>
                <Typography
                  variant="h5"
                  component="h1"
                  gutterBottom
                  align="center"
                >
                  Reset Your Password
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Enter your email address and we'll send you instructions to
                  reset your password.
                </Typography>
              </Box>

              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                  disabled={isSubmitting}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, py: 1.5 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Reset Password"}
                </Button>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    Remember your password?{" "}
                    <Typography
                      component="a"
                      href="/login"
                      variant="body2"
                      color="primary"
                      sx={{ textDecoration: "none", fontWeight: 500 }}
                    >
                      Back to login
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Box sx={{ textAlign: "center", py: 2 }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.success.light,
                  borderRadius: "50%",
                  p: 1,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 48,
                  height: 48,
                  margin: "0 auto",
                }}
              >
                <EmailIcon
                  size={24}
                  color={theme.palette.success.contrastText}
                />
              </Box>
              <Typography variant="h5" component="h1" gutterBottom>
                Check Your Email
              </Typography>
              <Typography variant="body1" paragraph>
                We've sent password reset instructions to:
              </Typography>
              <Typography variant="body1" fontWeight="bold" paragraph>
                {email}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                If you don't see the email, check your spam folder or{" "}
                <Typography
                  component="a"
                  href="#"
                  variant="body2"
                  color="primary"
                  sx={{ textDecoration: "none", fontWeight: 500 }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSubmitted(false);
                  }}
                >
                  try again
                </Typography>
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
}
