import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import Checkmark from "./AnimatedCheckmark";
import { useTheme } from "@mui/material/styles";
const WorkoutShareDialog = ({ open, link, handleClose }) => {
  const theme = useTheme();
  const [showCopyConfirmed, setShowCopyConfirmed] = useState(false);
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setShowCopyConfirmed(true);
    } catch (err) {
      alert("Failed to copy link: " + err);
    }
  };

  const close = () => {
    setShowCopyConfirmed(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3,
          background: theme.palette.background.paper,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6">Workout Shared Successfully!</Typography>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ pt: 2 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Share this link with your friends so they can view your workout:
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            p: 2,
            borderRadius: 1,
            wordBreak: "break-word",
          }}
        >
          <Typography
            variant="body2"
            component="a"
            href={link}
            target="_blank"
            sx={{ color: "primary.main", textDecoration: "underline" }}
          >
            {link}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={close} color="inherit" sx={{ fontWeight: 500 }}>
          Close
        </Button>
        {!showCopyConfirmed ? (
          <Button
            onClick={handleCopyToClipboard}
            variant="contained"
            color="primary"
          >
            Copy Link
          </Button>
        ) : (
          <Checkmark
            size={25}
            strokeWidth={4}
            color={theme.palette.success.main}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WorkoutShareDialog;
