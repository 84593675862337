import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

export default function ProgressBar({
  value,
  max = 100,
  color,
  label,
  showPercentage = false,
  className,
  animationDuration = 600,
}) {
  const [animatedValue, setAnimatedValue] = useState(0);

  // Normalize value to be between 0 and max
  const normalizedValue = Math.max(0, Math.min(value, max));
  const percentage = Math.round((normalizedValue / max) * 100);

  useEffect(() => {
    setAnimatedValue(0);
    const timeout = setTimeout(() => {
      setAnimatedValue(normalizedValue);
    }, 50);
    return () => clearTimeout(timeout);
  }, [normalizedValue]);

  return (
    <Box
      className={className}
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      {(label || showPercentage) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "0.875rem",
          }}
        >
          {label && <Typography variant="body2">{label}</Typography>}
          {showPercentage && (
            <Typography variant="body2">{percentage}%</Typography>
          )}
        </Box>
      )}
      <Box
        sx={{
          height: "8px",
          width: "100%",
          borderRadius: "9999px",
          backgroundColor: "#e0e0e0",
          overflow: "hidden",
        }}
        role="progressbar"
        aria-valuenow={normalizedValue}
        aria-valuemin={0}
        aria-valuemax={max}
        aria-label={label}
      >
        <Box
          sx={{
            height: "100%",
            borderRadius: "9999px",
            width: `${(animatedValue / max) * 100}%`,
            backgroundColor: color || "primary.main",
            transition: `width ${animationDuration}ms cubic-bezier(0.4, 0, 0.2, 1)`,
          }}
        />
      </Box>
    </Box>
  );
}
