import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import StyledBox from "../../shared/StyledBox";
import { Line } from "react-chartjs-2";
import { Typography } from "@mui/material";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ExerciseWeightChartCard = ({ exercise_name, chartData }) => {
  const theme = useTheme();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: theme.palette.text.primary,
        },
        grid: {
          color: theme.palette.divider,
        },
      },
      y: {
        ticks: {
          color: theme.palette.text.primary,
        },
        grid: {
          color: theme.palette.divider,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          color: theme.palette.text.primary,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  };
  return (
    <StyledBox width="100%" height="240px">
      <Typography
        variant="h6"
        component="div"
        color="text.secondary"
        gutterBottom
        sx={{ textTransform: "uppercase" }}
      >
        Weight Over Time
      </Typography>
      <Line options={options} data={chartData} />
    </StyledBox>
  );
};

export default ExerciseWeightChartCard;
