import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, IconButton } from "@mui/material";
import NavBar from "../../NavBar";
import { useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ExerciseStatsDashboard from "./ExerciseStatsDashboard";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ExerciseStatsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { exercise_name } = useParams();

  const { token } = useSelector((state) => state.authSlice);
  const [chartData, setChartData] = useState(null);
  const [maxWeight, setMaxWeight] = useState(null);
  const [estimatedOneRepMax, setEstimatedOneRepMax] = useState(null);
  const [percentChange, setPercentChange] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleGetExerciseStats = async () => {
      setLoading(true);
      const response = await axios.post(
        `/api/account/getExerciseStats`,
        {
          exercise_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      const labels = response.data
        ? response.data.stats.weightData.map((stats) =>
            dayjs(stats.created_at).format("MMM D")
          )
        : [];

      const weights = response.data
        ? response.data.stats.weightData.map((stats) => parseInt(stats.weight))
        : [];

      const data = {
        labels, // X-axis labels
        datasets: [
          {
            label: `${exercise_name} Set Weight`,
            data: weights, // Y-axis data
            borderColor: theme.palette.tertiary.main,
            backgroundColor: theme.palette.primary.light,
            tension: 0.3, // For smooth curve
            pointBackgroundColor: theme.palette.primary.dark,
          },
        ],
      };
      setMaxWeight(response.data.stats.maxWeight);
      setEstimatedOneRepMax(response.data.stats.estimated1RM);
      setPercentChange(response.data.stats.percentChangeData.percentageChange);
      setFrequency(response.data.stats.frequency);
      setChartData(data);
      setLoading(false);
    };
    if (exercise_name) {
      handleGetExerciseStats();
    }
  }, [
    token,
    exercise_name,
    theme.palette.primary,
    theme.palette.tertiary.main,
  ]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        className="main"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "scroll",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%", paddingBottom: "4px" }}>
          <NavBar />
        </Box>
        <Grid sx={{ paddingTop: "4px" }} container spacing={2}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "center",
            }}
            item
            xs={12}
          >
            <IconButton
              sx={{ paddingLeft: "12px" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {" "}
          {!loading ? (
            <ExerciseStatsDashboard
              exerciseName={exercise_name}
              maxWeight={maxWeight}
              estimatedOneRepMax={estimatedOneRepMax}
              percentChange={percentChange}
              frequency={frequency}
              chartData={chartData}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ExerciseStatsPage;
