import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Box,
  Divider,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  useTheme,
} from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SendIcon from "@mui/icons-material/Send";

const MAX_FEEDBACK_LENGTH = 1000;

const FeedbackDialog = ({
  open,
  onClose,
  handleReportFeedback,
  productName = "Weightliftr",
}) => {
  const [feedback, setFeedback] = useState("");
  const [feedbackType, setFeedbackType] = useState("general");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();

  const handleSubmit = async () => {
    if (feedback.trim()) {
      setIsSubmitting(true);
      try {
        await handleReportFeedback(feedback, feedbackType);
        setFeedback("");
        setFeedbackType("general");
        onClose();
      } catch (error) {
        console.error("Error submitting feedback:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const getFeedbackTypeIcon = (type) => {
    switch (type) {
      case "bug":
        return <BugReportIcon color="error" />;
      case "feature":
        return <LightbulbIcon sx={{ color: theme.palette.warning.main }} />;
      case "general":
        return <FeedbackIcon color="info" />;
      default:
        return <FeedbackIcon />;
    }
  };

  const getFeedbackTypeDescription = (type) => {
    switch (type) {
      case "bug":
        return "Report an issue or unexpected behavior";
      case "feature":
        return "Suggest a new feature or improvement";
      case "general":
        return "Share your thoughts or suggestions";
      default:
        return "";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FeedbackIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
          <Typography variant="h6" component="span">
            Product Feedback
          </Typography>
        </Box>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ pt: 3 }}>
        <Typography variant="body2" sx={{ mb: 3 }}>
          Thank you for taking the time to provide feedback on {productName}.
          Your insights are valuable and will help us enhance the product
          experience for all users.
        </Typography>

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="feedback-type-label">Feedback Category</InputLabel>
          <Select
            labelId="feedback-type-label"
            id="feedback-type"
            value={feedbackType}
            onChange={(e) => setFeedbackType(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                {getFeedbackTypeIcon(feedbackType)}
              </InputAdornment>
            }
          >
            <MenuItem value="bug">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BugReportIcon
                  sx={{ mr: 1, color: theme.palette.error.main }}
                />
                <Box>
                  <Typography variant="body1">Bug Report</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Report an issue or unexpected behavior
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem value="feature">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LightbulbIcon
                  sx={{ mr: 1, color: theme.palette.warning.main }}
                />
                <Box>
                  <Typography variant="body1">Feature Request</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Suggest a new feature or improvement
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem value="general">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FeedbackIcon sx={{ mr: 1, color: theme.palette.info.main }} />
                <Box>
                  <Typography variant="body1">General Feedback</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Share your thoughts or suggestions
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          </Select>
          <FormHelperText>
            {getFeedbackTypeDescription(feedbackType)}
          </FormHelperText>
        </FormControl>

        <TextField
          label="Feedback Details"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={feedback}
          onChange={(e) => {
            if (e.target.value.length <= MAX_FEEDBACK_LENGTH) {
              setFeedback(e.target.value);
            }
          }}
          placeholder={
            feedbackType === "bug"
              ? "Please describe the issue in detail. Include steps to reproduce if possible."
              : feedbackType === "feature"
              ? "Please describe your feature request. What problem would it solve?"
              : "Please share your thoughts, suggestions, or experiences with our product."
          }
          helperText={`${feedback.length}/${MAX_FEEDBACK_LENGTH} characters`}
          FormHelperTextProps={{
            sx: {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            },
          }}
          sx={{ mb: 3 }}
        />

        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ display: "block", mt: 2 }}
        >
          Your feedback will be used to improve our products and services. We
          respect your privacy and will handle your information in accordance
          with our Privacy Policy.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={onClose} color="inherit" sx={{ fontWeight: 500 }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!feedback.trim() || isSubmitting}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <SendIcon />
            )
          }
        >
          {isSubmitting ? "Submitting..." : "Submit Feedback"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
